import React, { useEffect, useReducer, useState } from "react";
import { validate } from "../../util/validator";
import "./UploadImage.css";
import loadingSVG from "../../../assets/images/ic-loading.svg";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const UploadImage = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue,
    isTouched: false,
    isValid: props.initialValid || false,
  });

  const [isLoading, setLoading] = useState(false);

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = async (event) => {
    const tranformedAcceptedFiles = async (files) => {
      setLoading(true);
      const heic2any = (await import("heic2any")).default;
      const data = await Promise.all(
        Array.from(files).map((file) => {
          return heic2any({ blob: file, toType: "image/jpeg" })
            .then((fileBlob) => {
              const newFile = new File([fileBlob], file.name + ".jpg", {
                type: "image/jpeg",
              });

              return newFile;
            })
            .catch(() => {
              return file;
            });
        })
      );

      return data;
    };

    const newArray = await tranformedAcceptedFiles(event.target.files);

    if (newArray && newArray[0].size <= 5000000) {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = `${Date.now()}`;
        const imageData = reader.result;
        const matches = imageData.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
        const imageExtension = imageData.match(
          /data:image\/([a-zA-Z]*);base64,([^"]*)/
        );
        const type = matches[1];
        const photoOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            filename: fileName + "." + imageExtension[1],
            ImageData: imageData,
            Type: type,
          }),
        };

        fetch("/submissions/photos/create", photoOptions)
          .then((response) => {
            const responseData = response.json();
            if (
              response.status === 503 &&
              responseData.then((data) => data.reason === "maintenance")
            ) {
              window.location.href = "/Maintenance";
            }
            return responseData;
          })
          .then((responseData) => {
            if (responseData.status === 400) {
              dispatch({
                type: "CHANGE",
                val: "",
                validators: props.validators,
              });
            } else {
              setTimeout(() => {
                if (responseData.link) {
                  dispatch({
                    type: "CHANGE",
                    val: responseData.link,
                    validators: props.validators,
                  });
                } else {
                  dispatch({
                    type: "CHANGE",
                    val: "",
                    validators: props.validators,
                  });
                }
              }, 100);
            }
          })
          .catch(() => {
            dispatch({
              type: "CHANGE",
              val: "",
              validators: props.validators,
            });
          })
          .finally(() => {
            props.handleInvalidSize(true);
            setLoading(false);
          });
      };

      reader.onerror = () => {
        setLoading(false);
      };

      reader.readAsDataURL(newArray[0]);
    } else {
      setLoading(false);
    }

    if (newArray[0].size > 5000000) {
      props.handleInvalidSize(false);
    }
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
    props.touchConst();
  };

  const loadingContainer = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: "#fff",
      }}
    >
      <img
        src={loadingSVG}
        width="30px"
        height="30px"
        alt="loading"
        className="text-align-center spin"
      />
    </div>
  );

  return (
    <div
      className={`text-align-center pb-20 ${
        !inputState.isValid &&
        (inputState.isTouched || props.isTouched) &&
        "form-control--invalid"
      }`}
    >
      <div
        className="photos"
        style={{
          position: "relative",
          backgroundImage: `url(${
            props.initialValue !== "" ? inputState.value : props.initialImage
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          cursor: isLoading ? "" : "pointer",
          opacity: `${props.initialValue !== "" ? 1 : 0.6}`,
        }}
      >
        {isLoading ? <>{loadingContainer}</> : <></>}
        <div
          className={`circle plus pointer-events ${props.circleClass}`}
        ></div>
        <input
          id={props.id}
          type="file"
          onChange={changeHandler}
          onBlur={touchHandler}
          className="input-photos"
          accept="image/x-png,image/gif,image/jpeg"
        />
      </div>
      <label htmlFor={props.id} className="mute padding-t-8">
        <p>{props.label}</p>
      </label>
    </div>
  );
};

export default UploadImage;
