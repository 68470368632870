import React from 'react';
import './termsandFAQs.css';

import Container from '../shared/components/UIElements/Container';

const FAQs = () => {
	return (
		<React.Fragment>
			<Container>
				<div className="maxWidth829 center">
					<h1 className="reserlfridges-buyback pt-50">RESELFRIDGES FAQs</h1>

					<div className='faq'>
						<hr className="black"/>
						<input id='watta' type='checkbox' />
						<label htmlFor='watta' className="">
							<p className="faq-heading">What is RESELFRIDGES?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">RESELFRIDGES is a new initiative that enables you to buy pre-loved items and sell pre-loved designer bags from selected brands in exchange for Selfridges credits to spend in store or online. It’s just one way of helping to close the loop on unnecessary landfill waste.</p>
						</label>

						<hr className="black"/>
						<input id='faq-a' type='checkbox' />
						<label htmlFor='faq-a'>
							<p className="faq-heading">Which countries is this service available?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">At the moment, our resell platform is only available in the UK and Europe, excluding Switzerland, Iceland, Norway and Liechtenstein.</p>
						</label>
						<hr className="black"/>

						<input id='faq-b' type='checkbox' />
						<label htmlFor='faq-b' className="large">
							<p className="faq-heading">Which brands do you accept?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> We currently only accept bags from the following brands: Alexander McQueen, Alexander Wang, Balenciaga, Balmain, Bottega Veneta, Burberry, Bulgari, Celine, Chanel, Dior, Dolce & Gabbana, Fendi, Givenchy, Goyard, Gucci, Hermès, Jacquemus, Loewe, Louis Vuitton, MCM, Miu Miu, Mulberry, Off-White, Prada, Saint Laurent, Salvatore Ferragamo, Stella McCartney, The Row, Valentino and Versace.<br />
Watch this space, though – the list is growing all the time! <br />
Your bag does not have to been bought from RESELFRIDGES originally; you can sell any bag from our list of accepted brands.</p>
						</label>
						<hr className="black"/>
  
						<input id='faq-d' type='checkbox' />
						<label htmlFor='faq-d'>
							<p className="faq-heading">What products and materials do you not accept?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">Please refer to our <a href="https://images.selfridges.com/is/content/selfridges/content/site/pdf/Ethical_Trade_Requirements_Aug2020.pdf" target="_blank" rel="noreferrer noopener"><u>Ethical Trade Requirements (ETRs)</u></a> that set out the core principles that all suppliers working with Selfridges must comply with, in particular Section 6 which stipulates the prohibited products and materials that we do not sell or use. Our ETRs are subject to change.</p>
						</label>
						<hr className="black"/>

						<input id='c' type='checkbox' />
						<label htmlFor='c'>
							<p className="faq-heading">Can I sell more than one bag?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   Yes. You may submit as many bags as you want, but you will need to fill in a new submission form for each item via selfridges.com.</p>
						</label>
						<hr className="black"/>

						<input id='d' type='checkbox' />
						<label htmlFor='d'>
							<p className="faq-heading">Who do I contact if I have additional questions?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">     The RESELFRIDGES Customer Services team are here to help. Please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad, dial +44 (0) 20 7160 6222, between 8am and 11pm.</p>
						</label>
						<hr className="black"/>

						<input id='e' type='checkbox' />
						<label htmlFor='e' className="medium">
							<p className="faq-heading">How do I know which size category my bag falls into?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   Please refer to our size guidelines: <br />
    XS: 20 x 17 x 13 cm <br />
    S: 25 x 19 x 15 cm <br />
    M: 30 x 21 x 17 cm <br />
    L: 35 x 23 x 18 cm <br />
    XL: 40 x 25 x 19 cm<br /></p>
						</label>
						<hr className="black"/>

						<input id='f' type='checkbox' />
						<label htmlFor='f'>
							<p className="faq-heading">I’ve submitted my bag(s), when will I receive my quote?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    We will send you a quote via email within 5 working days (Monday to Friday, excluding holidays).</p>
						</label>
						<hr className="black"/>

						<input id='g' type='checkbox' />
						<label htmlFor='g'>
							<p className="faq-heading">How is the price I’m quoted for my bag(s) determined?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  We work with a team of valuation experts to evaluate your bag. The quote that you are given will be a competitive trade price that is based on several factors, including brand, authenticity, condition and the demand for that particular style.</p>
						</label>
						<hr className="black"/>

						<input id='h' type='checkbox' />
						<label htmlFor='h'>
							<p className="faq-heading">Will any fees be subtracted from the price that I am quoted? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> No. The price that you are quoted is the amount that you will receive in RESELFRIDGES credit.</p>
						</label>
						<hr className="black"/>

						<input id='i' type='checkbox' />
						<label htmlFor='i'>
							<p className="faq-heading">What happens if I’m not happy with the quote?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> Unfortunately, at this time, all prices quoted are final and are not subject to further negotiation. If you are not happy with our quote, simply reply to the email and decline our offer. There is no obligation to accept our quote.</p>
						</label>
						<hr className="black"/>

						<input id='j' type='checkbox' />
						<label htmlFor='j'>
							<p className="faq-heading">How do I accept the quote that you have given me? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> To accept our quote, simply reply to the email we sent you with details of the offer. You have 14 days to accept or decline our offer. (If you still wish to sell your item after the offer has expired, you will need to re-submit photos of your bag to receive a new quote.)</p>
						</label>
						<hr className="black"/>

						<input id='k' type='checkbox' />
						<label htmlFor='k'>
							<p className="faq-heading">Can the original price I’m quoted change after you receive my bag(s)?  </p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> No, the price you are quoted is non-negotiable and a one-time offer. If your bag is not selected for resale, we will send it back to you, free of charge.</p>
						</label>
						<hr className="black"/>

						<input id='l' type='checkbox' />
						<label htmlFor='l'>
							<p className="faq-heading">Why haven’t I received my quote yet?  </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  From the date of submission, quotes may take up to 5 days to be confirmed. If you have not received a quote after this time, please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad +44 (0) 20 7160 6222 between 8am and 11pm.</p>
						</label>
						<hr className="black"/>
 
						<input id='m' type='checkbox' />
						<label htmlFor='m'>
							<p className="faq-heading">How long is my quote valid for? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   Your quote is valid for 14 days. If you still wish to sell your item after the offer has expired, you will need to re-submit photos of your bag to receive a new quote.</p>
						</label>
						<hr className="black"/>

						<input id='n' type='checkbox' />
						<label htmlFor='n'>
							<p className="faq-heading">I’ve been asked to send more photos – why? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   Additional photos are sometimes needed to ensure the team of valuation experts give you the most accurate quote possible. If additional photos have been requested, we will not be able to proceed with the evaluation of your bag until they have been received.</p>
						</label>
						<hr className="black"/>

						<input id='o' type='checkbox' />
						<label htmlFor='o' className="medium">
							<p className="faq-heading">How and when will I be paid?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   Payment will be given in RESELFRIDGES credits on a Selfridges eGift Card, which you can spend in store or at selfridges.com. Once you have accepted your quote and the valuation team has successfully received and verified your bag, the credit will be transferred onto a Selfridges eGift Card within 12 days of it arriving with the valuation experts. These credits can be used immediately and do not expire. Unfortunately, at this time, we are unable to offer any other forms of payment (e.g. money). We will update you in the future if this changes.</p>
						</label>
						<hr className="black"/>

						<input id='p' type='checkbox' />
						<label htmlFor='p'>
							<p className="faq-heading">What is RESELFRIDGES credit and what can I use it for? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   RESELFRIDGES credit is ‘money’ that will be added to a Selfridges eGift Card, which you can spend in store or at selfridges.com.</p>
						</label>
						<hr className="black"/>
  
						<input id='q' type='checkbox' />
						<label htmlFor='q'>
							<p className="faq-heading">I haven’t received my RESELFRIDGES credit yet – why?  </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    If you haven’t received your RESELFRIDGES credit yet, it is likely that your item is still being evaluated. You will receive an email notification as soon as your credit has been processed (this normally takes no more than 12 days from when the bag arrived with the valuation experts).</p>
						</label>
						<hr className="black"/>
    
						<input id='r' type='checkbox' />
						<label htmlFor='r'>
							<p className="faq-heading">Can I transfer my RESELFRIDGES credit to family or friends?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    Unfortunately, RESELFRIDGES credit isn’t transferable.</p>
						</label>
						<hr className="black"/>

						<input id='s' type='checkbox' />
						<label htmlFor='s'>
							<p className="faq-heading">How long will my Selfridges eGift Card be valid for?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  Don’t worry, your Selfridges eGift Card will never expire.</p>
						</label>
						<hr className="black"/>

						<input id='t' type='checkbox' />
						<label htmlFor='t' className="medium">
							<p className="faq-heading">How do I send my items to RESELFRIDGES? Are there any shipping costs?  </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    Sending your items to RESELFRIDGES is a quick, free and easy process. Once you have accepted your quote, you can schedule a pick-up time with us. We use DHL for our collections and deliveries; they offer three-hour collection/delivery slots. Once a pick-up has been scheduled, we will send you our packaging guidelines to help you prepare your item for collection, as well as the DHL tracking number and shipping label, which you will need to print out. (Please note, the valuation experts are based in Estonia.).</p>
						</label>
						<hr className="black"/>

						<input id='u' type='checkbox' />
						<label htmlFor='u'>
							<p className="faq-heading">Can I change my pick-up address?    </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  Unfortunately, this is not possible at the moment. If you have any questions, please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad please call +44 (0) 20 7160 6222 between 8am and 11pm.<br /></p>
						</label>
						<hr className="black"/>
  
						<input id='w' type='checkbox' />
						<label htmlFor='w' className="medium">
							<p className="faq-heading">I missed the courier – can I reschedule another pick-up time?    </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  Yes. If you need to reschedule your pick-up, please contact DHL Express either via their website www.dhl.com/en/express or on 0345 072 0278 (please note, this number is for UK-based customers only) and quote your DHL tracking number. Alternatively, please email resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad, call +44 (0) 20 7160 6222, between 8am and 11pm, and quote your DHL tracking number. Please note, we can only arrange up to three pick-ups.<br /></p>
						</label>
						<hr className="black"/>
    
						<input id='x' type='checkbox' />
						<label htmlFor='x'>
							<p className="faq-heading">Why haven’t you received my item yet? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  Shipping delays may occur over the weekend or during holiday periods. You can track your item via the DHL Express website www.dhl.com/en/express using the DHL tracking number provided via email.<br /></p>
						</label>
						<hr className="black"/>
      
						<input id='y' type='checkbox' />
						<label htmlFor='y' className="medium">
							<p className="faq-heading">What happens if my item gets lost during shipment?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text"> All items are insured and provided with tracking details for monitoring purposes. If you are concerned that your shipment has been lost, please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad call +44 (0) 20 7160 6222 between 8am and 11pm and quote your DHL tracking number provided via email.<br /></p>
						</label>
						<hr className="black"/>

						<input id='aa' type='checkbox' />
						<label htmlFor='aa'>
							<p className="faq-heading">Can I drop off my item at Selfridges?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">Unfortunately, this is not something that we are able to offer at the moment. We’ll update you if this changes in the future.<br /></p>
						</label>
						<hr className="black"/>
  
						<input id='ab' type='checkbox' />
						<label htmlFor='ab'>
							<p className="faq-heading">Where will my item be sent to for valuation?    </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">Your bag will be sent to a team of valuation experts in Estonia..<br /></p>
						</label>
						<hr className="black"/>
  
						<input id='ac' type='checkbox' />
						<label htmlFor='ac'>
							<p className="faq-heading">I can’t find my label or shipping instructions. Can you help?  </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">Please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad, call +44 (0) 20 7160 6222 between 8am and 11pm, and quote your DHL tracking number provided via email.<br /></p>
						</label>
						<hr className="black"/>

						<input id='ae' type='checkbox' />
						<label htmlFor='ae' className="medium">
							<p className="faq-heading">How will I know that the valuation experts have received my bag?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">RESELFRIDGES team to let you know your bag has arrived with the valuation experts. You can also use the DHL tracking number given via email to view the shipment status on the DHL Express website www.dhl.com/en/express. If you have any issues, please contact DHL Express on 0345 072 0278 (please note, this number is for UK-based customers only). Alternatively, you can email us at resellfridges@selfridges.com or call us on +44 (0) 800 123 400. If calling from abroad, please dial +44 (0) 20 7160 6222 between 8am and 11pm and quote your DHL tracking number.<br /></p>
						</label>
						<hr className="black"/>

						<input id='af' type='checkbox' />
						<label htmlFor='af'>
							<p className="faq-heading">What happens when you receive my item? How long does the process take?   </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">The team of valuation experts will assess your bag as soon as they receive it. Once it has passed their quality checks, we will transfer the credits to a Selfridges eGift Card, which you can spend in store or at selfridges.com. This should take no longer than 12 days from the time your item reached the experts.<br /></p>
						</label>
						<hr className="black"/>

						<input id='ag' type='checkbox' />
						<label htmlFor='ag'>
							<p className="faq-heading">How do you authenticate the bags? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">We work with a team of third-party valuation experts to ensure the authentication of the items we receive. We have a rigorous process to ensure that all items meet our authenticity standards.<br /></p>
						</label>
						<hr className="black"/>
  
						<input id='ah' type='checkbox' />
						<label htmlFor='ah' className="large">
							<p className="faq-heading">Why would my bag not pass the evaluation?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">Your item may not be approved by the valuation experts if it differs from what was expected, based on the photos and information that you submitted. Some examples of why your bag may not be approved are listed below:<br />
· your bag is not a style we’re currently accepting.<br />
· your bag is not in a suitable condition.<br />
· the valuation experts can’t confirm the authenticity of your bag.<br />
· your bag is from a brand we don’t stock.<br />
· Your bag is from a category we don’t stock.<br /><br /></p>
						</label>
						<hr className="black"/>
  
						<input id='ai' type='checkbox' />
						<label htmlFor='ai'>
							<p className="faq-heading">What happens to my bag if it is not approved?</p>
							<div className='faq-arrow'></div>
							<p className="faq-text">  You will receive an email from us if your item is not approved by the team of valuation experts. We will send your bag back via DHL to the same address that the pick-up was arranged from, free of charge, within five working days. When the item is delivered, we will require a signature for tracking confirmation.</p>
						</label>
						<hr className="black"/>

						<input id='aj' type='checkbox' />
						<label htmlFor='aj'>
							<p className="faq-heading">What if I change my mind and decide that I want my bag to be returned? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    Unfortunately, once you have accepted our quote and sent us your bag, we are unable to cancel the purchase of your bag. Please see our Terms of Service for more information.</p>
						</label>
						<hr className="black"/>

						<input id='ak' type='checkbox' />
						<label htmlFor='ak' className="extra-extra-extra-large">
							<p className="faq-heading">What can I do if I can’t submit my bag online? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">   In order for us to make a submission on your behalf, our Customer Services team needs the following information:<br />
Personal details: <br />
1. Full name <br />
2. Full pick-up address (street, city, postcode, country) <br />
3. Email address <br />
4. Mobile number <br />
Bag details: <br />
1. Minimum of 4 photos <br />
2. Brand <br />
3. Size <br />
4. Condition<br />
Please send an email to resellfridges@selfridges.com or call us on +44 (0) 800 123 400, or if calling from abroad, dial +44 (0) 20 7160 6222, between 8am and 11pm.</p>
						</label>
						<hr className="black"/>

						<input id='al' type='checkbox' />
						<label htmlFor='al' className="">
							<p className="faq-heading">Can you schedule a pick-up for me? </p>
							<div className='faq-arrow'></div>
							<p className="faq-text">    In order for us to schedule a pick-up on your behalf, our Customer Services team needs the following information: <br />
								<br />
1. Full pick-up address (street, city, postcode, country) <br />
2. Preferred pick-up date (minimum 3 days in advance)<br />
3. Preferred pick-up time<br /></p>
						</label>
						<hr className="black"/>

					</div>

				</div>
      
			</Container>
		</React.Fragment>
	);
};

export default FAQs;