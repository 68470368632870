import React from 'react';
import './../pages/QuoteCreation.css';

const QuoteCreationHeader = (props) => {
	return (
		<React.Fragment>
			<div className="center text-align-center max-width-516">
				<h1 className="so-whats-next padding-b-3">SO, WHAT&apos;S NEXT?</h1>
				<p className="details pb-50">Follow these simple steps to submit your bag and within 4 days we’ll tell you how much credit you could earn.</p>
			</div>
			<div className="max-width-684 center ">
				<div className="d-flex flex-wrap justify-content-center padding-b-48">

					<div className="d-flex">
						<div className={`${props.first ? 'oval-primary background-primary color-white' : 'background-secondary color-white oval-secondary'} align-self-center padding-add`}>
                            1
						</div>
						<p className="details marginl-r24 align-self-center remove-when-mobile">
							<b>Your details</b>
						</p>
					</div>

					<div className="d-flex">
						<div className={`${props.second ? 'oval-primary background-primary color-white' : 'background-secondary color-white oval-secondary'} align-self-center padding-add`}>
                            2
						</div>
						<p className="details marginl-r24 align-self-center remove-when-mobile">
							<b>Your bag details</b>
						</p>
					</div>

					<div className="d-flex">
						<div className={`${props.third ? 'oval-primary background-primary color-white' : 'background-secondary color-white oval-secondary'} align-self-center padding-add`}>
                            3
						</div>
						<p className="details margin-l-24 align-self-center remove-when-mobile">
							<b>Review & confirm</b>
						</p>
					</div>
				</div>
			</div>

		</React.Fragment>
	);
};

export default QuoteCreationHeader;
