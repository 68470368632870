import React from 'react';
import Modal from '../../shared/components/UIElements/Modal';
import Container from '../../shared/components/UIElements/ContainerFluid';
import './ModalCondition.css';
import Button from '../../shared/components/UIElements/Button';

const ModalCondition = props => {
	return (
		<Modal
			show={props.show}
			onCancel={props.onCancel}
		>
			<Container className="p-t-75 p-b-20">
				<div className="text-align-center">
					<h1 className="title-resellfridges">RESELFRIDGES</h1>
					<p className="condition p-b-48">Condition guidelines</p>
				</div>

				<div className="row  margin-114 ">
					<div className="col-md-3 p-b-48">
						<p className="condition"><b>PRISTINE (A+)</b> </p>
					</div>

					<div className="col-md-9 p-b-48" >
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3"> The item is new or has barely been used. It shows no wear and tear and comes with a box, dust bag, and authenticity card (where relevant).</p>
						</div>
					</div>

					<div className="col-md-3 p-b-48">
						<p className="condition"><b>EXCELLENT (A)</b> </p>
					</div>

					<div className="col-md-9 p-b-48">
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3">The item is in impeccable condition with barely noticeable signs of use.</p>
						</div>
					</div>

					<div className="col-md-3 p-b-48">
						<p className="condition"><b>VERY GOOD (AB)</b> </p>
					</div>

					<div className="col-md-9 p-b-48">
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3">The item has been used but very well taken care of. There are limited signs of wear and these will be described in detail and/or visually declared in the photos.</p>
						</div>
					</div>

					<div className="col-md-3 p-b-48">
						<p className="condition"><b>GOOD (B+)</b> </p>
					</div>

					<div className="col-md-9 p-b-48">
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3"> The item has been used and has visible signs of wear, e.g. there may be slight patina (a gloss or sheen resulting from age or polishing) and other indications of use. All signs of use will be described in detail and/or visually declared in the photos.</p>
						</div>
					</div>

					<div className="col-md-3 p-b-48">
						<p className="condition"><b>USED (B)</b> </p>
					</div>

					<div className="col-md-9 p-b-48">
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3"> The item has been well loved and may have scratches or other more obvious signs of wear. All imperfections will be described in detail and/or visually declared in the photos.</p>
						</div>
					</div>

					<div className="col-md-3 p-b-48">
						<p className="condition"><b>POOR (BC)</b> </p>
					</div>

					<div className="col-md-9 p-b-48">
						<div className="d-flex">
							<p className="yellowSquare " />
							<p className="condition margin-l-3">The item is in poor condition and/or with significant signs of wear and tear. It may not be suitable for everyday use and/or may need restoring.</p>
						</div>
					</div>

				</div>

			</Container>

			<div className="text-align-center p-b-160">
				<Button className="background-secondary" onClick={props.onCancel}>
                    Close
				</Button>

			</div>
		</Modal>
	);
};

export default ModalCondition;