import React, { useReducer, useEffect } from 'react';

import { validate } from '../../util/validator';
import './Select.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators)
			};
		case 'TOUCH': {
			return {
				...state,
				isTouched: true
			};
		}
		default:
			return state;
	}
};

const Input = props => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isTouched: false,
		isValid: props.initialValid || false
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		onInput(id, value, isValid);
	}, [id, value, isValid, onInput]);

	const changeHandler = event => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
			validators: props.validators
		});
	};

	const touchHandler = () => {
		dispatch({
			type: 'TOUCH'
		});
	};

	const element = (
		<select
			id={props.id}
			onChange={changeHandler}
			onBlur={touchHandler}
			value={inputState.value}
			className={inputState.value|| 'selected-placeholder'}
		>
			{props.children}
		</select>
	);

	const note = props.id=== 'condition'?(
		<label className="note"><b onClick={props.onClick} className="note">PLEASE REVIEW OUR CONDITION GUIDELINES</b>
		</label>
	):'';

	return (
		<div
			className={`form-control ${!inputState.isValid && inputState.isTouched &&
        'form-control--invalid'}`}
		>
			<label htmlFor={props.id}>{props.label}</label>
			{note}
			{element}
			{props.id==='material' && <p className="select-black mute">Please refer to our <a href="/FAQs" target="_blank" rel="noreferrer noopener"><b><u>FAQs</u></b></a> for a list of prohibited products and materials</p>}
			{!inputState.isValid && inputState.isTouched && props.errorText &&<p>{props.errorText}</p>}
		</div>
	);
};

export default Input;
