import React, { useContext, useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { useHistory } from 'react-router-dom';
import loadingSVG from '../../assets/images/ic-loading.svg';
import CustomCheckbox from '../../shared/components/FormElements/CustomCheckbox';
import Input from '../../shared/components/FormElements/Input';
import Select from '../../shared/components/FormElements/Select';
import UploadImage from '../../shared/components/FormElements/UploadImage';
import Button from '../../shared/components/UIElements/Button';
import Container from '../../shared/components/UIElements/Container';
import { FormContext } from '../../shared/context/form-context';
import { useForm } from '../../shared/hooks/form-hook';
import { brandValidate, validate } from '../../shared/util/brandImagePicker';
import { formInitial2 } from '../../shared/util/formVariables';
import { FILE, NONE, REQUIRE } from '../../shared/util/validator';
import ModalCondition from '../components/ModalCondition';
import ModalGuideline from '../components/ModalGuideline';
import QuoteCreationHeader from '../components/QuoteCreationHeader';
import './QuoteCreation.css';

const QuoteCreation2 = () => {
	const form = useContext(FormContext);

	const [isLoading, setIsLoading] = useState(true);
	const [loadingImage, setLoadingImage] = useState(false);

	const [scrollPosition, setScrollPosition] = useState(false);
	const [touch, setTouched] = useState(false);
	const [showConditions, setShowConditions] = useState(false);
	const [showGuidelines, setShowGuidelines] = useState(false);
	const formInitial = formInitial2();
	const [formState, inputHandler, setFormData] = useForm(formInitial, {
		isValid: false,
	});
	const [imageState, ImageHandler, setImageData] = useForm(
		{},
		{ isValid: false }
	);
	const [isSizeValid, setIsSizeValid] = useState(true);

	const identifiedForm = form.quote2;

	useEffect(() => {
		if (Object.keys(identifiedForm).length !== 0) {
			setFormData(identifiedForm, { isValid: true });
		}
		setIsLoading(false);
	}, [setFormData, identifiedForm]);

	const [elementBrand, setElementBrand] = useState({});
	const brand = formState.inputs.brand.value;
	const imageInitial = Object.keys(form.quoteImages).length !== 0
		? form.quoteImages
		: brandValidate(brand);

	useEffect(() => {
		setLoadingImage(true);
		setImageData({});
		const brandResponse = async () => {
			if (brand !== '') {
				await setElementBrand(validate(brand));
				setImageData(imageInitial);
				if(Object.keys(form.quoteImages).length !==0) {
					form.quoteImagesHandler({});
				}
			}

		};

		brandResponse();
		setLoadingImage(false);
	}, [brand, setElementBrand, setImageData]);

	const handleInvalidSize = (isValidSize) => {
		setIsSizeValid(isValidSize);
	};

	const history = useHistory();
	const submitHandler = (event) => {
		event.preventDefault();

		if (!imageState.isValid) {
			Object.keys(imageState.inputs).forEach((key) => {
				const imageInput = imageState.inputs[key];
				if (!imageInput.isValid) {
					imageInput.isTouched = true;
				}
			});
			console.log(imageState.inputs);
			setImageData(imageState.inputs, imageState.isValid);
			imageValidator();
			return;
		}

		form.quoteCreation2Handler(formState.inputs);
		form.quoteImagesHandler(imageState.inputs);

		ReactGa.event({
			category: 'Next Button',
			action: 'Bag Details'
		});
		history.push('/QuoteCreation3');
	};

	// const test = (event) => {
	//   event.preventDefault();
	//   console.log(formState.inputs)
	//   console.log(imageState.inputs)
	// };

	const openGuidelinesModalHandler = (event) => {
		event.preventDefault();
		setScrollPosition(window.pageYOffset);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', `overflow: hidden;position: fixed;   width: 100%;   left: 0;
    top: 0;`);

		ReactGa.event({
			category: 'View Photo Guidelines',
			action: 'Opens Modal Guidelines'
		});
		setShowGuidelines(true);
	};

	const closeGuidelinesModalHandler = (event) => {
		event.preventDefault();
		window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', '');
		setShowGuidelines(false);
	};

	const openConditionModalHandler = (event) => {
		event.preventDefault();
		setScrollPosition(window.pageYOffset);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', `overflow: hidden;position: fixed;   width: 100%;   left: 0;
    top: 0;`);

		ReactGa.event({
			category: 'View Condition Guidelines',
			action: 'Opens Condition Guidelines'
		});
		setShowConditions(true);
	};

	const closeConditionModalHandler = (event) => {
		event.preventDefault();
		window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', '');
		setShowConditions(false);
	};

	const imageValidator = () => {
		setTouched(true);
	};

	const loadingContainer = (

		<div className="text-align-center">
			<br/>
			<br/>
			<img
				src={loadingSVG}
				width="30px"
				height="30px"
				alt="loading"
				className="text-align-center spin"
			/>
		</div>
	);

	if (isLoading) {
		return loadingContainer;
	}

	return (
		<React.Fragment>
			<ModalCondition
				show={showConditions}
				onCancel={closeConditionModalHandler}
			/>
			<ModalGuideline
				show={showGuidelines}
				onCancel={closeGuidelinesModalHandler}
			/>
			<Container className="padding-small">
				<QuoteCreationHeader second="true" />
				<div className="center max-width-684">
					<p className="details letter-spacing-1 padding-b-24">
						<b>Your bag details</b>
					</p>
					<form onSubmit={submitHandler}>
						<div className="row">
							<div className="col-md-12 padding-b-24">
								<Select
									label="Brand"
									validators={[REQUIRE()]}
									id="brand"
									errorText="Please pick a brand"
									onInput={inputHandler}
									initialValue={formState.inputs.brand.value}
									initialValid={formState.inputs.brand.isValid}
								>
									<option value="" hidden>
                    Select brand
									</option>
									<option value="Alexander McQueen">Alexander McQueen</option>
									<option value="Alexander Wang">Alexander Wang</option>
									<option value="Balenciaga">Balenciaga</option>
									<option value="Balmain">Balmain</option>
									<option value="Bottega Veneta">Bottega Veneta</option>
									<option value="Burberry">Burberry</option>
									<option value="Bulgari">Bulgari</option>
									<option value="Celine">Celine</option>
									<option value="Chanel">Chanel</option>
									<option value="Dior">Dior</option>
									<option value="Dolce & Gabbana">Dolce & Gabbana</option>
									<option value="Fendi">Fendi</option>
									<option value="Givenchy">Givenchy</option>
									<option value="Goyard">Goyard</option>
									<option value="Gucci">Gucci</option>
									<option value="Hermès">Hermès</option>
									<option value="Jacquemus">Jacquemus</option>
									<option value="Louis Vuitton">Louis Vuitton</option>
									<option value="Loewe">Loewe</option>
									<option value="MCM">MCM</option>
									<option value="Miu Miu">Miu Miu</option>
									<option value="Mulberry">Mulberry</option>
									<option value="Off-White">Off-White</option>
									<option value="Prada">Prada</option>
									<option value="Saint Laurent">Saint Laurent</option>
									<option value="Salvatore Ferragamo">
                    Salvatore Ferragamo
									</option>
									<option value="Stella McCartney">Stella McCartney</option>
									<option value="The Row">The Row</option>
									<option value="Valentino">Valentino</option>
									<option value="Versace">Versace</option>
								</Select>
							</div>

							<div className="col-md-6 padding-b-24">
								<Input
									element="input"
									id="style"
									type="text"
									label="Style (Optional)"
									validators={[NONE()]}
									placeholder="Style"
									errorText="Style is required."
									onInput={inputHandler}
									initialValue={formState.inputs.style.value}
									initialValid={formState.inputs.style.isValid}
								/>
							</div>

							<div className="col-md-6 padding-b-24">
								<Select
									label="Size"
									validators={[REQUIRE()]}
									id="size"
									errorText="Please pick a size"
									onInput={inputHandler}
									initialValue={formState.inputs.size.value}
									initialValid={formState.inputs.size.isValid}
								>
									<option value="" hidden>
                    Select size
									</option>
									<option value="Extra Small">Extra Small</option>
									<option value="Small">Small</option>
									<option value="Medium">Medium</option>
									<option value="Large">Large</option>
									<option value="Extra Large">Extra Large</option>
								</Select>
							</div>

							<div className="col-md-6 padding-b-24">
								<Select
									label="Material (Optional)"
									validators={[NONE()]}
									id="material"
									errorText="Please pick a material"
									onInput={inputHandler}
									initialValue={formState.inputs.material.value}
									initialValid={formState.inputs.material.isValid}
								>
									<option value="" hidden>
                    Select material
									</option>
									<option value="18K White Gold Metal">
                    18K White Gold Metal
									</option>
									<option value="18K Yellow Gold Metal">
                    18K Yellow Gold Metal
									</option>
									<option value="Brass Metal">Brass Metal</option>
									<option value="Calf Hair Natural Material">
                    Calf Hair Natural Material
									</option>
									<option value="Calf Leather">Calf Leather</option>
									<option value="Canvas Fabric">Canvas Fabric</option>
									<option value="Caviar Leather">Caviar Leather</option>
									<option value="Chemical Fibre Fabric">
                    Chemical Fibre Fabric
									</option>
									<option value="Coated Canvas Fabric">
                    Coated Canvas Fabric
									</option>
									<option value="Corduroy Fabric">Corduroy Fabric</option>
									<option value="Cotton Fabric">Cotton Fabric</option>
									<option value="Damier Canvas">Damier Canvas</option>
									<option value="Denim Fabric">Denim Fabric</option>
									<option value="Diamond Natural Material">
                    Diamond Natural Material
									</option>
									<option value="Enamel Metal">Enamel Metal</option>
									<option value="Enamel Other">Enamel Other</option>
									<option value="Epi Leather">Epi Leather</option>
									<option value=" Faux Pearl Other"> Faux Pearl Other</option>
									<option value="Fibre Fabric">Fibre Fabric</option>
									<option value="Gold-Plated Metal">Gold-Plated Metal</option>
									<option value="Hemp Natural Material">
                    Hemp Natural Material
									</option>
									<option value="Jacquard Fabric">Jacquard Fabric</option>
									<option value="Knit Fabric">Knit Fabric</option>
									<option value="Lambskin Leather">Lambskin Leather</option>
									<option value="Limestone Natural Material">
                    Limestone Natural Material
									</option>
									<option value="Mesh Fabric">Mesh Fabric</option>
									<option value="Monogram Canvas">Monogram Canvas</option>
									<option value="Monogram Empreinte Leather">
                    Monogram Empreinte Leather
									</option>
									<option value="Monogram Multi-colour Canvas">
                    Monogram Multi-colour Canvas
									</option>
									<option value="Nappa Leather">Nappa Leather</option>
									<option value="Nubuck Leather">Nubuck Leather</option>
									<option value="Nylon Fabric">Nylon Fabric</option>
									<option value="Patent Leather">Patent Leather</option>
									<option value="Pearl Natural Material">
                    Pearl Natural Material
									</option>
									<option value="Platinum Metal">Platinum Metal</option>
									<option value="Polyester Fabric"> Polyester Fabric</option>
									<option value="Pony Hair Natural Material">
                    Pony Hair Natural Material
									</option>
									<option value="Other">Other</option>
								</Select>
							</div>

							<div className="col-md-6 padding-b-24">
								<Select
									label="Colour"
									validators={[REQUIRE()]}
									id="colour"
									errorText="Please pick a colour"
									onInput={inputHandler}
									initialValue={formState.inputs.colour.value}
									initialValid={formState.inputs.colour.isValid}
								>
									<option value="" hidden>
                    Select colour
									</option>
									<option value="Black">Black</option>
									<option value="Blue">Blue</option>
									<option value="Blue Bluemarine">Blue Bluemarine</option>
									<option value="Blue Dark Blue">Blue Dark Blue</option>
									<option value="Blue Denim">Blue Denim</option>
									<option value="Blue Light Blue">Blue Light Blue</option>
									<option value="Blue Midnight Blue">Blue Midnight Blue</option>
									<option value="Blue Navy">Blue Navy</option>
									<option value="Blue Turquoise">Blue Turquoise</option>
									<option value="Brown">Brown</option>
									<option value="Brown Beige">Brown Beige</option>
									<option value="Brown Bronze">Brown Bronze</option>
									<option value="Brown Camel">Brown Camel</option>
									<option value="Brown Dark Brown">Brown Dark Brown</option>
									<option value="Brown Dark Khaki">Brown Dark Khaki</option>
									<option value="Brown Khaki"> Brown Khaki</option>
									<option value="Brown Light Brown">Brown Light Brown</option>
									<option value="Brown Taupe">Brown Taupe</option>
									<option value="Grey">Grey</option>
									<option value="Grey Charcoal">Grey Charcoal</option>
									<option value="Grey Dark Grey"> Grey Dark Grey</option>
									<option value="Grey Pumice">Grey Pumice</option>
									<option value="Green">Green</option>
									<option value="Green Dark Green">Green Dark Green</option>
									<option value="Green Light Green">Green Light Green</option>
									<option value="Green Mint">Green Mint</option>
									<option value="Multi">Multi</option>
									<option value="Orange">Orange</option>
									<option value="Pink">Pink</option>
									<option value="Pink Dark Pink">Pink Dark Pink</option>
									<option value="Pink Light Pink">Pink Light Pink</option>
									<option value="Pink Hot Pink">Pink Hot Pink</option>
									<option value="Pink Lily">Pink Lily</option>
									<option value="Pink Marshmallow">Pink Marshmallow</option>
									<option value="Pink Rose">Pink Rose</option>
									<option value="Purple">Purple</option>
									<option value="Purple Lavender"> Purple Lavender</option>
									<option value="Purple Plum">Purple Plum</option>
									<option value="Purple Violet">Purple Violet</option>
									<option value="Red">Red</option>
									<option value="Red Bordeaux">Red Bordeaux</option>
									<option value="Red Burgundy">Red Burgundy</option>
									<option value="Red Maroon">Red Maroon</option>
									<option value="Red Pink">Red Pink</option>
									<option value="Silver">Silver</option>
									<option value="White">White</option>
									<option value="White Ivory">White Ivory</option>
									<option value="Yellow">Yellow</option>
									<option value="Yellow Mustard">Yellow Mustard</option>
								</Select>
							</div>

							<div className="col-md-12 padding-b-24">
								<Select
									label="Condition"
									validators={[REQUIRE()]}
									onClick={openConditionModalHandler}
									id="condition"

									onInput={inputHandler}
									initialValue={formState.inputs.condition.value}
									initialValid={formState.inputs.condition.isValid}
								>
									<option value="" hidden>
                    Select condition
									</option>
									<option value="Pristine">Pristine</option>
									<option value="Excellent">Excellent</option>
									<option value="Very Good">Very Good</option>
									<option value="Good">Good</option>
									<option value="Used">Used</option>
									<option value="Poor">Poor</option>
								</Select>
							</div>
						</div>

						<p className="any-extras">Any extras?</p>
						<p className="mute padding-b-20">
              Please indicate any original accessories that you are able to
              include:
						</p>

						<div className="d-flex padding-b-48 flex-wrap content-center-mobile">
							<div className="width-100">
								<CustomCheckbox
									label="BOX"
									id="box"
									onInput={inputHandler}
									initialValue={formState.inputs.box.value}
									initialValid={formState.inputs.box.isValid}
								/>
							</div>

							<div className="width-208">
								<CustomCheckbox
									label="AUTHENTICITY CARD"
									id="authenticityCard"
									onInput={inputHandler}
									initialValue={formState.inputs.authenticityCard.value}
									initialValid={formState.inputs.authenticityCard.isValid}
								/>
							</div>

							<div className="width-187">
								<CustomCheckbox
									label="SHOULDER STRAP"
									id="shoulderStrap"
									onInput={inputHandler}
									initialValue={formState.inputs.shoulderStrap.value}
									initialValid={formState.inputs.shoulderStrap.isValid}
								/>
							</div>

							<div className="width-133">
								<CustomCheckbox
									label="DUST BAG"
									id="dustBag"
									onInput={inputHandler}
									initialValue={formState.inputs.dustBag.value}
									initialValid={formState.inputs.dustBag.isValid}
								/>
							</div>

							<div className="width-121">
								<CustomCheckbox
									label="POUCH"
									id="pouch"
									onInput={inputHandler}
									initialValue={formState.inputs.pouch.value}
									initialValid={formState.inputs.pouch.isValid}
								/>
							</div>

							<div className="width-184">
								<CustomCheckbox
									label="PADLOCK & KEY"
									id="padlockKey"
									onInput={inputHandler}
									initialValue={formState.inputs.padlockKey.value}
									initialValid={formState.inputs.padlockKey.isValid}
								/>
							</div>

							<div className="width-160 ">
								<CustomCheckbox
									label="BAG CHARM"
									id="bagCharm"
									onInput={inputHandler}
									initialValue={formState.inputs.bagCharm.value}
									initialValid={formState.inputs.bagCharm.isValid}
								/>
							</div>

							<div className="width-137">
								<CustomCheckbox
									label="MIRROR"
									id="mirror"
									onInput={inputHandler}
									initialValue={formState.inputs.mirror.value}
									initialValid={formState.inputs.mirror.isValid}
								/>
							</div>
						</div>
						<p className="attach">TIME TO ATTACH YOUR PHOTOS</p>

						<p className="mute padding-b-48">
							<b>Please upload all photos marked with an asterisk</b>, as well
              as any extras you think might help the valuation experts to assess
              your bag. Remember: the more images you provide, the more accurate
              your quote will be. To find out what condition we’ll accept your
              bag in, please review our submission guidelines.
						</p>

						<div className="text-align-center">
							<Button
								className="background-secondary"
								onClick={openGuidelinesModalHandler}
							>
                Review our submission guidelines
							</Button>
						</div>

						{formState.inputs.brand.isValid && loadingImage === false ? (
							<React.Fragment>
								<div className=" padding-small justify-content-center text-align-center">
									{imageState.isValid === false && touch === true ?(
										<p className="error">Please upload all the required images</p>
									):''}
									{isSizeValid === false ? (
										<p className="error">Image must be smaller than 5MB</p>
									):''}
									<div className="d-flex flex-wrap justify-content-center ">
										{imageState.inputs.frontImage && imageInitial.frontImage? (
											<UploadImage
												id="frontImage"
												type="text"
												label={`Front${
													!imageInitial.frontImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.frontImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="Front image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.frontImage.value}
												initialValid={imageState.inputs.frontImage.isValid}
												initialImage={elementBrand.frontImage}
												isTouched={imageState.inputs.frontImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.backImage && imageInitial.backImage ? (
											<UploadImage
												id="backImage"
												type="text"
												label={`Back${
													!imageInitial.backImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.backImage.isValid ? [NONE()] : [FILE()]
												}
												circleClass=""
												errorText="back image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.backImage.value}
												initialValid={imageState.inputs.backImage.isValid}
												initialImage={elementBrand.backImage}
												isTouched={imageState.inputs.backImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.bottomImage && imageInitial.bottomImage ? (
											<UploadImage
												id="bottomImage"
												type="text"
												label={`Bottom${
													!imageInitial.bottomImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.bottomImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="bottom image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.bottomImage.value}
												initialValid={imageState.inputs.bottomImage.isValid}
												initialImage={elementBrand.bottomImage}
												isTouched={imageState.inputs.bottomImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.insideImage && imageInitial.insideImage ? (
											<UploadImage
												id="insideImage"
												type="text"
												label={`Inside${
													!imageInitial.insideImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.insideImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="inside image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.insideImage.value}
												initialValid={imageState.inputs.insideImage.isValid}
												initialImage={elementBrand.insideImage}
												isTouched={imageState.inputs.insideImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.interiorLogoImage &&
                  imageInitial.interiorLogoImage ? (
												<UploadImage
													id="interiorLogoImage"
													type="text"
													label={`Interior Logo${
														!imageInitial.interiorLogoImage.isValid ? '*' : ''
													}`}
													validators={
														imageInitial.interiorLogoImage.isValid
															? [NONE()]
															: [FILE()]
													}
													errorText="interior logo is required."
													onInput={ImageHandler}
													initialValue={imageState.inputs.interiorLogoImage.value}
													initialValid={imageState.inputs.interiorLogoImage.isValid}
													initialImage={elementBrand.interiorLogoImage}
													isTouched={imageState.inputs.interiorLogoImage.isTouched}
													touchConst={imageValidator}
													handleInvalidSize={handleInvalidSize}
												/>
											) : (
												''
											)}

										{imageState.inputs.labelImage && imageInitial.labelImage ? (
											<UploadImage
												id="labelImage"
												type="text"
												label={`Label${
													!imageInitial.labelImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.labelImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="label image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.labelImage.value}
												initialValid={imageState.inputs.labelImage.isValid}
												initialImage={elementBrand.labelImage}
												isTouched={imageState.inputs.labelImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}
										{imageState.inputs.serialImage && imageInitial.serialImage ? (
											<UploadImage
												id="serialImage"
												type="text"
												label={`Serial Number${
													!imageInitial.serialImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.serialImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="Serial Code image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.serialImage.value}
												initialValid={imageState.inputs.serialImage.isValid}
												initialImage={elementBrand.serialImage}
												isTouched={imageState.inputs.serialImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.hardwareImage &&
                  imageInitial.hardwareImage ? (
												<UploadImage
													id="hardwareImage"
													type="text"
													label={`Hardware${
														!imageInitial.hardwareImage.isValid ? '*' : ''
													}`}
													validators={
														imageInitial.hardwareImage.isValid ? [NONE()] : [FILE()]
													}
													errorText="Hardware image is required."
													onInput={ImageHandler}
													initialValue={imageState.inputs.hardwareImage.value}
													initialValid={imageState.inputs.hardwareImage.isValid}
													initialImage={elementBrand.hardwareImage}
													isTouched={imageState.inputs.hardwareImage.isTouched}
													touchConst={imageValidator}
													handleInvalidSize={handleInvalidSize}
												/>
											) : (
												''
											)}

										{imageState.inputs.zipperImage && imageInitial.zipperImage ? (
											<UploadImage
												id="zipperImage"
												type="text"
												label={`Zipper${
													!imageInitial.zipperImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.zipperImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="Zipper image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.zipperImage.value}
												initialValid={imageState.inputs.zipperImage.isValid}
												initialImage={elementBrand.zipperImage}
												isTouched={imageState.inputs.zipperImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}

										{imageState.inputs.damageImage && imageInitial.damageImage ? (
											<UploadImage
												id="damageImage"
												type="text"
												label={`Damage${
													!imageInitial.damageImage.isValid ? '*' : ''
												}`}
												validators={
													imageInitial.damageImage.isValid ? [NONE()] : [FILE()]
												}
												errorText="Damage image is required."
												onInput={ImageHandler}
												initialValue={imageState.inputs.damageImage.value}
												initialValid={imageState.inputs.damageImage.isValid}
												initialImage={elementBrand.damageImage}
												isTouched={imageState.inputs.damageImage.isTouched}
												touchConst={imageValidator}
												handleInvalidSize={handleInvalidSize}
											/>
										) : (
											''
										)}
									</div>
								</div>
							</React.Fragment>
						) : (
							loadingContainer
						)}

						<div className="padding-t-48 padding-b-24">
							<p className="mute">
								<b>IMPORTANT: </b>If there is any damage please let us know and
                provide a photo of the damage – without this we will not be able
                to provide a quote.
							</p>
						</div>

						<div className="text-align-center">
							<Button
								className="background-primary padding-l-r-37"
								disabled={
									formState.isValid && imageState.isValid ? false : true
								}
							>
                Next Step
							</Button>

							{/* <Button
                className="background-primary padding-l-r-37"
                onClick={test}
              >
                Test
              </Button> */}
						</div>
					</form>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default QuoteCreation2;
