import React, { useContext, useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { Link, useHistory } from 'react-router-dom';
import loadingSVG from '../../assets/images/ic-loading.svg';
import Input from '../../shared/components/FormElements/Input';
import Select from '../../shared/components/FormElements/Select';
import Button from '../../shared/components/UIElements/Button';
import Container from '../../shared/components/UIElements/Container';
import { FormContext } from '../../shared/context/form-context';
import { useForm } from '../../shared/hooks/form-hook';
import { formInitial1 } from '../../shared/util/formVariables';
import { CONFIRMEMAIL, EMAIL, MINLENGTH, REQUIRE } from '../../shared/util/validator';
import QuoteCreationHeader from '../components/QuoteCreationHeader';
import './QuoteCreation.css';

const QuoteCreation1 = () => {

	const form = useContext(FormContext);
	const [isLoading, setIsLoading] = useState(true);

	const formInitials = formInitial1();
	const [formState, inputHandler, setFormData] = useForm(formInitials, { isValid: false }
	);

	const identifiedForm = form.quote1;
	useEffect(() => {
		if (Object.keys(identifiedForm).length !== 0) {
			setFormData(identifiedForm);
		}
		setIsLoading(false);
	}, [setFormData, identifiedForm]);

	const history = useHistory();
	const submitHandler = event => {
		event.preventDefault();
		form.quoteCreation1Handler(formState.inputs);
		ReactGa.event({
			category: 'Next Button',
			action: 'Personal Details'
		});

		history.push('/QuoteCreation2');
	};

	if (isLoading) {
		return (
			<div className="text-align-center">
				<br/>
				<br/>
				<img
					src={loadingSVG}
					width="30px"
					height="30px"
					alt="loading"
					className="text-align-center spin"
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<Container className="padding-small">
				<QuoteCreationHeader first="true" />
				<div className="center max-width-684">
					<p className="details letter-spacing-1 padding-b-24">
						<b>YOUR DETAILS</b>
					</p>
					<form onSubmit={submitHandler}>

						<div className="row">
							<div className="col-md-6 padding-b-24">
								<Input element="input"
									id="firstName"
									type="text"
									label="First Name"
									validators={
										[REQUIRE()]
									}
									placeholder="Your first name"
									errorText="First name is required."
									onInput={inputHandler}
									initialValue={
										formState.inputs.firstName.value
									}
									initialValid={
										formState.inputs.firstName.isValid
									} />
							</div>
							<div className="col-md-6 padding-b-24">
								<Input element="input" type="text" label="Last Name"
									id="lastName"
									validators={
										[REQUIRE()]
									}
									placeholder="Your last name"
									errorText="Last Name is required."
									onInput={inputHandler}
									initialValue={
										formState.inputs.lastName.value
									}
									initialValid={
										formState.inputs.lastName.isValid
									} />
							</div>

							<div className="col-md-12 padding-b-24">
								<Input element="input" type="email" label="Email Address"
									id="emailAddress"
									validators={
										[EMAIL()]
									}
									placeholder="Your email address"
									errorText="Please enter a valid email address"
									onInput={inputHandler}
									initialValue={
										formState.inputs.emailAddress.value
									}
									initialValid={
										formState.inputs.emailAddress.isValid
									} />
							</div>

							<div className="col-md-12 padding-b-24">
								<Input element="input" type="email" label="Please confirm your email"
									id="emailAddressConfirmation"
									validators={
										[CONFIRMEMAIL(formState.inputs.emailAddress.value)]
									}
									placeholder="Your email address"
									errorText="Your email addresses do not match. Please check and try again."
									onInput={inputHandler}
									initialValue={
										formState.inputs.emailAddressConfirmation.value
									}
									initialValid={
										formState.inputs.emailAddressConfirmation.isValid
									}
								/>

							</div>

							<div className="col-md-12 padding-b-24">
								<Select label="Country"
									validators={
										[REQUIRE()]
									}
									id="country"
									errorText="Please pick a country"
									onInput={inputHandler}
									initialValue={
										formState.inputs.country.value
									}
									initialValid={
										formState.inputs.country.isValid
									}>

									<option value="" hidden>Select your country</option>
									<option value="Austria">Austria</option>
									<option value="Belgium">Belgium</option>
									<option value="Bulgaria">Bulgaria</option>
									<option value="Croatia">Croatia</option>
									<option value="Cyprus">Cyprus</option>
									<option value="Czech Republic">Czech Republic</option>
									<option value="Denmark">Denmark</option>
									<option value="Estonia">Estonia</option>
									<option value="Finland">Finland</option>
									<option value="France">France</option>
									<option value="Germany">Germany</option>
									<option value="Greece">Greece</option>
									<option value="Hungary">Hungary</option>
									<option value="Ireland">Ireland</option>
									<option value="Italy">Italy</option>
									<option value="Latvia">Latvia</option>
									<option value="Lithuania">Lithuania</option>
									<option value="Luxembourg">Luxembourg</option>
									<option value="Malta">Malta</option>
									<option value="Netherlands">Netherlands</option>
									<option value="Poland">Poland</option>
									<option value="Portugal">Portugal</option>
									<option value="Romania">Romania</option>
									<option value="Slovakia">Slovakia</option>
									<option value="Slovenia">Slovenia</option>
									<option value="Spain">Spain</option>
									<option value="Sweden">Sweden</option>
									<option value="United Kingdom">United Kingdom</option>

								</Select>
							</div>

							<div className="col-md-12 padding-b-24">
								<Input element="input" type="tel" label="Phone Number"
									validators={
										[MINLENGTH(6)]
									}
									id="phoneNumber"
									placeholder="Enter your mobile number"
									errorText="Please enter a valid phone number"
									onInput={inputHandler}
									initialValue={
										formState.inputs.phoneNumber.value
									}
									initialValid={
										formState.inputs.phoneNumber.isValid
									} />
							</div>

						</div>

						<div className="text-align-center">
							<Button className="background-primary"
								disabled={!formState.isValid}>
                Next Step
							</Button>
						</div>

					</form>
				</div>
				<p className="mute text-align-center padding-small p-t-72 margin-24-when-mb">

          For more information about RESELFRIDGES, please read our
					<Link to="/FAQs"><u>FAQs</u></Link> and
					<Link to="/TermsConditions"><u>Terms & Conditions</u></Link>.
          For how we handle your personal information, please see our
					<a href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/"><u>Privacy & Cookie Policy</u></a>.

				</p>
			</Container>

		</React.Fragment>
	);
};

export default QuoteCreation1;
