export const formInitial1 = () => {
	return(

		{
			firstName: {
				value: '',
				isValid: false
			},
			lastName: {
				value: '',
				isValid: false
			},
			emailAddress: {
				value: '',
				isValid: false
			},
			emailAddressConfirmation: {
				value: '',
				isValid: false
			},
			country: {
				value: '',
				isValid: false
			},
			phoneNumber: {
				value: '',
				isValid: false
			},
			sendQuote: {
				value: false,
				isValid: true
			}
		}

	);

};

export const formInitial2 = () => {
	return(
		{
			category:{
				value: 'bags',
				isValid: true
			},
			brand: {
				value: '',
				isValid: false
			},
			style: {
				value: '',
				isValid: true
			},
			size: {
				value: '',
				isValid: false
			},
			material: {
				value: '',
				isValid: true
			},
			colour: {
				value: '',
				isValid: false
			},
			condition: {
				value: '',
				isValid: false
			},
			box: {
				value: false,
				isValid: true
			},
			authenticityCard: {
				value: false,
				isValid: true
			},
			shoulderStrap: {
				value: false,
				isValid: true
			},
			dustBag: {
				value: false,
				isValid: true
			},
			pouch: {
				value: false,
				isValid: true
			},
			padlockKey: {
				value: false,
				isValid: true
			},
			bagCharm: {
				value: false,
				isValid: true
			},
			mirror: {
				value: false,
				isValid: true
			}
		}
	);

};
