import React, { useReducer, useEffect } from 'react';
import './Checkbox.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: !state.value,
				isValid: true
			};
		default:
			return state;
	}
};

const Checkbox = props => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue,
		isValid: props.initialValid || true
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		onInput(id, value, isValid);
	}, [id, value, isValid, onInput]);

	const changeHandler = event => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
		});

	};

	const element = (
		<input
			id={props.id}
			type="checkbox"
			onChange={changeHandler}
			checked={inputState.value}
			value={inputState.value}
			className="padding-r-24 normal-checkbox"
		/>
	);

	return (
		<div className="form-control d-flex ">
			{element}
			<label htmlFor={props.id} className="normal-label"><div>{props.label}</div></label>
		</div>
	);
};

export default Checkbox;
