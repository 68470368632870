import React, { useState } from 'react';
import ModalConfirmation from '../components/ModalConfirmation';
import '../components/ModalCondition.css';
import '../components/ModalConfirmation.css';
import { useHistory } from 'react-router-dom';
import ReactGa from 'react-ga';

const Confirmation = () => {
	const [confirmation, showConfirmation] = useState(true);

	const history = useHistory();
	const closeShowConfirmation = event => {
		event.preventDefault();
		showConfirmation(false);
		ReactGa.event({
			category: 'Confirmation',
			action: 'Back to Home Page' 
		}); 
		history.push('/');
	};

	return(
		<div className="vh-100">
			<ModalConfirmation
				show={confirmation}
				onCancel={closeShowConfirmation}
			/>
		</div>
	);
};

export default Confirmation;