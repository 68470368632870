const alexanderMcqueen = "Alexander McQueen";
const alexanderWang = "Alexander Wang";
const balenciaga = "Balenciaga";
const bottegaVeneta = "Bottega Veneta";
const burberry = "Burberry";
const bvlgari = "Bulgari";
const celine = "Celine";
const chanel = "Chanel";
const dior = "Dior";
const dolceGabanna = "Dolce & Gabbana";
const fendi = "Fendi";
const givenchy = "Givenchy";
const gucci = "Gucci";
const hermes = "Hermès";
const jacquemus = "Jacquemus";
const louisVuitton = "Louis Vuitton";
const loewe = "Loewe";
const mcm = "MCM";
const miuMiu = "Miu Miu";
const mulberry = "Mulberry";
const offWhite = "Off-White";
const prada = "Prada";
const salvatoreFerragamo = "Salvatore Ferragamo";
const stellaMcCartney = "Stella McCartney";
const theRow = "The Row";
const valentino = "Valentino";
const versace = "Versace";
const saintLaurent = "Saint Laurent";
const goyard = "Goyard";
const balmain = "Balmain";

const dns = "https://stresellfridges.blob.core.windows.net/";

export const validate = (value) => {
  if (value === alexanderMcqueen) {
    const brandImages = {
      frontImage: dns + "AlexanderMcqueen_Front.jpg",
      backImage: dns + "AlexanderMcqueen_Back.jpg",
      zipperImage: dns + "AlexanderMcqueen_Zipper.jpg",
      damageImage: dns + "AlexanderMcqueen_Damage.jpg",
      bottomImage: dns + "AlexanderMcqueen_Bottom.jpg",
      insideImage: dns + "AlexanderMcqueen_Inside.jpg",
      interiorLogoImage: dns + "AlexanderMcqueen_InteriorLogo.jpg",
      labelImage: dns + "AlexanderMcqueen_ExteriorLogo.jpg",
      serialImage: dns + "AlexanderMcqueen_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === alexanderWang) {
    const brandImages = {
      frontImage: dns + "AlexanderWang_Front.jpg",
      backImage: dns + "AlexanderWang_Back.jpg",
      zipperImage: dns + "AlexanderWang_Zipper.jpg",
      damageImage: dns + "AlexanderWang_Damage.jpg",
      bottomImage: dns + "AlexanderWang_Bottom.jpg",
      insideImage: dns + "AlexanderWang_Inside.jpg",
      interiorLogoImage: dns + "AlexanderWang_InteriorLogo.jpg",
      labelImage: dns + "Alexander%20Wang_Exterior%20Logo.jpg",
    };

    return brandImages;
  }

  if (value === balenciaga) {
    const brandImages = {
      frontImage: dns + "Balenciaga_Front.jpg",
      backImage: dns + "Balenciaga_Back.jpg",
      zipperImage: dns + "Balenciaga_Zipper.jpg",
      damageImage: dns + "Balenciaga_Damage.jpg",
      bottomImage: dns + "Balenciaga_Bottom.jpg",
      insideImage: dns + "Balenciaga_Inside.jpg",
      interiorLogoImage: dns + "Balenciaga_InteriorLogo.jpg",
      serialImage: dns + "Balenciaga_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === balmain) {
    const brandImages = {
      frontImage: dns + "Balmain_Front.jpg",
      backImage: dns + "Balmain_Back.jpg",
      zipperImage: dns + "Balmain_Hardware.jpg",
      damageImage: dns + "Balmain_Damage.jpg",
      bottomImage: dns + "Balmain_Bottom.jpg",
      insideImage: dns + "Balmain_Inside.jpg",
      interiorLogoImage: dns + "Balmain_InteriorLogo.jpg",
      labelImage: dns + "Balmain_ExteriorLogo.jpg",
    };

    return brandImages;
  }

  if (value === bottegaVeneta) {
    const brandImages = {
      frontImage: dns + "Bottega%20Veneta_Front.jpg",
      backImage: dns + "Bottega%20Veneta_Back.jpg",
      zipperImage: dns + "Bottega%20Veneta_Hardware.jpg",
      damageImage: dns + "Bottega%20Veneta_Damage.jpg",
      bottomImage: dns + "Bottega%20Veneta_Bottom.jpg",
      insideImage: dns + "Bottega%20Veneta_Inside.jpg",
      interiorLogoImage: dns + "Bottega%20Veneta_InteriorLogo.jpg",
      serialImage: dns + "Bottega%20Veneta_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === burberry) {
    const brandImages = {
      frontImage: dns + "Burberry_Front.jpg",
      backImage: dns + "Burberry_Back.jpg",
      hardwareImage: dns + "Burberry_Hardware.jpg",
      damageImage: dns + "Burberry_Damage.jpg",
      bottomImage: dns + "Burberry_Bottom.jpg",
      insideImage: dns + "Burberry_Inside.jpg",
      interiorLogoImage: dns + "Burberry_InteriorLogo.jpg",
      labelImage: dns + "Burberry_ExteriorLogo.jpg",
      serialImage: dns + "Burberry_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === bvlgari) {
    const brandImages = {
      frontImage: dns + "Bvlgari_Front.jpg",
      backImage: dns + "Bvlgari_Back.jpg",
      zipperImage: dns + "Bvlgari_Zipper.jpg",
      damageImage: dns + "Bvlgari_Damage.jpg",
      bottomImage: dns + "Bvlgari_Bottom.jpg",
      insideImage: dns + "Bvlgari_Inside.jpg",
      interiorLogoImage: dns + "Bvlgari_InteriorLogo.jpg",
      labelImage: dns + "Bvlgari_ExteriorLogo.jpg",
      serialImage: dns + "Bvlgari_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === celine) {
    const brandImages = {
      frontImage: dns + "Celine_Front.jpg",
      backImage: dns + "Celine_Back.jpg",
      zipperImage: dns + "Celine_Zipper.jpg",
      damageImage: dns + "Celine_Damage.jpg",
      bottomImage: dns + "Celine_Bottom.jpg",
      insideImage: dns + "Celine_Inside.jpg",
      hardwareImage: dns + "Celine_Hardware.jpg",
      interiorLogoImage: dns + "Celine_InteriorLogo.jpg",
      labelImage: dns + "Celine_ExteriorLogo.jpg",
      serialImage: dns + "Celine_SerialCode.jpg",
    };

    return brandImages;
  }

  if (value === chanel) {
    const brandImages = {
      frontImage: dns + "Chanel_Front.jpg",
      backImage: dns + "Chanel_Back.jpg",
      zipperImage: dns + "Chanel_Zipper.jpg",
      damageImage: dns + "Chanel_Damage.jpg",
      bottomImage: dns + "Chanel_Bottom.jpg",
      interiorLogoImage: dns + "Chanel_InteriorLogo.jpg",
      labelImage: dns + "Chanel_ExteriorLogo.jpg",
      serialImage: dns + "Chanel_SerialCode.jpg",
    };
    return brandImages;
  }

  if (value === dior) {
    const brandImages = {
      frontImage: dns + "Dior_Front.jpg",
      backImage: dns + "Dior_Back.jpg",
      zipperImage: dns + "Dior_Zipper.jpg",
      damageImage: dns + "Dior_Damage.jpg",
      bottomImage: dns + "Dior_Bottom.jpg",
      insideImage: dns + "Dior_Inside.jpg",
      interiorLogoImage: dns + "Dior_InteriorLogo.jpg",
      serialImage: dns + "Dior_SerialCode.jpg",
      hardwareImage: dns + "Dior_BrandedHardware.jpg",
    };
    return brandImages;
  }

  if (value === dolceGabanna) {
    const brandImages = {
      frontImage: dns + "Dolce_Gabbana_Front.jpg",
      backImage: dns + "Dolce_Gabbana_Back.jpg",
      damageImage: dns + "Dolce_Gabbana_Damage.jpg",
      bottomImage: dns + "Dolce_Gabbana_Bottom.jpg",
      hardwareImage: dns + "Dolce_Gabbana_Hardware.jpg",
      insideImage: dns + "Dolce_Gabbana_Inside.jpg",
      interiorLogoImage: dns + "Dolce_Gabbana_InteriorLogo.jpg",
      labelImage: dns + "Dolce_Gabbana_ExteriorLogo.jpg",
    };
    return brandImages;
  }

  if (value === fendi) {
    const brandImages = {
      frontImage: dns + "Fendi_Front.jpg",
      backImage: dns + "Fendi_Back.jpg",
      damageImage: dns + "Fendi_Damage.jpg",
      bottomImage: dns + "Fendi_Bottom.jpg",
      insideImage: dns + "Fendi_Inside.jpg",
      interiorLogoImage: dns + "Fendi_InteriorLogo.jpg",
      labelImage: dns + "Fendi_ExteriorLogo.jpg",
      serialImage: dns + "Fendi_SerialCode.jpg",
      hardwareImage: dns + "Fendi_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === givenchy) {
    const brandImages = {
      frontImage: dns + "Givenchy_Front.jpg",
      backImage: dns + "Givenchy_Back.jpg",
      damageImage: dns + "Givenchy_Damage.jpg",
      bottomImage: dns + "Givenchy_Bottom.jpg",
      insideImage: dns + "Givenchy_Inside.jpg",
      interiorLogoImage: dns + "Givenchy_InteriorLogo.jpg",
      labelImage: dns + "Givenchy_ExteriorLogo.jpg",
      serialImage: dns + "Givenchy_SerialCode.jpg",
      hardwareImage: dns + "Givenchy_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === goyard) {
    const brandImages = {
      frontImage: dns + "Goyard_Front.jpg",
      backImage: dns + "Goyard_Back.jpg",
      damageImage: dns + "Goyard_Damage.jpg",
      bottomImage: dns + "Goyard_Bottom.jpg",
      insideImage: dns + "Goyard_Inside.jpg",
      interiorLogoImage: dns + "Goyard_InteriorLogo.jpg",
      serialImage: dns + "Goyard_SerialCode.jpg",
      hardwareImage: dns + "Goyard_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === gucci) {
    const brandImages = {
      frontImage: dns + "Gucci_Front.jpg",
      backImage: dns + "Gucci_Back.jpg",
      zipperImage: dns + "Gucci_Zipper.jpg",
      damageImage: dns + "Gucci_Damage.jpg",
      bottomImage: dns + "Gucci_Bottom.jpg",
      insideImage: dns + "Gucci_Inside.jpg",
      interiorLogoImage: dns + "Gucci_InteriorLogo.jpg",
      labelImage: dns + "Gucci_ExteriorLogo.jpg",
      serialImage: dns + "Gucci_SerialCode.jpg",
      hardwareImage: dns + "Gucci_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === hermes) {
    const brandImages = {
      frontImage: dns + "Hermes_Front.jpg",
      backImage: dns + "Hermes_Back.jpg",
      zipperImage: dns + "Hermes_Zipper.jpg",
      damageImage: dns + "Hermes_Damage.jpg",
      bottomImage: dns + "Hermes_Bottom.jpg",
      insideImage: dns + "Hermes_Inside.jpg",
      interiorLogoImage: dns + "Hermes_InteriorLogo.jpeg",
      labelImage: dns + "Hermes_ExteriorLogo.jpg",
      serialImage: dns + "Hermes_SerialCode.jpeg",
      hardwareImage: dns + "Hermes_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === jacquemus) {
    const brandImages = {
      frontImage: dns + "Jacquemus_Front.jpg",
      backImage: dns + "Jacquemus_Back.jpg",
      damageImage: dns + "Jacquemus_Damage.jpg",
      bottomImage: dns + "Jacquemus_Bottom.jpg",
      insideImage: dns + "Jacquemus_Inside.jpg",
      interiorLogoImage: dns + "Jacquemus_InteriorLogo.jpg",
      labelImage: dns + "Jacquemus_ExteriorLogo.jpg",
    };
    return brandImages;
  }

  if (value === loewe) {
    const brandImages = {
      frontImage: dns + "Loewe_Front.jpg",
      backImage: dns + "Loewe_Back.jpg",
      zipperImage: dns + "Loewe_Zipper.jpg",
      damageImage: dns + "Loewe_Damage.jpg",
      bottomImage: dns + "Loewe_Bottom.jpg",
      insideImage: dns + "Loewe_Inside.jpg",
      interiorLogoImage: dns + "Loewe_InteriorLogo.jpg",
      labelImage: dns + "Loewe_ExteriorLogo.jpg",
      hardwareImage: dns + "Loewe_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === louisVuitton) {
    const brandImages = {
      backImage: dns + "Louis%20Vuitton_Back.jpg",
      bottomImage: dns + "Louis%20Vuitton_Bottom.jpg",
      labelImage: dns + "Louis%20Vuitton_ExteriorLogo.jpg",
      frontImage: dns + "Louis%20Vuitton_Front.jpg",
      insideImage: dns + "Louis%20Vuitton_Inside.jpg",
      interiorLogoImage: dns + "Louis%20Vuitton_InteriorLogo.jpg",
      serialImage: dns + "Louis%20Vuitton_SerialCode.jpg",
      zipperImage: dns + "Louis%20Vuitton_Zipper.jpg",
      damageImage: dns + "Louis%20Vuitton_Damage.jpg",
      hardwareImage: dns + "Louis%20Vuitton_BrandedHardware.jpg",
    };
    return brandImages;
  }

  if (value === mcm) {
    const brandImages = {
      backImage: dns + "MCM_Back.jpg",
      bottomImage: dns + "MCM_Bottom.jpeg",
      labelImage: dns + "MCM_ExteriorLogo.jpg",
      frontImage: dns + "MCM_Front.jpg",
      insideImage: dns + "MCM_Inside.jpg",
      interiorLogoImage: dns + "MCM_InteriorLogo.jpg",
      serialImage: dns + "MCM_SerialCode.jpeg",
      zipperImage: dns + "MCM_Zipper.jpg",
      damageImage: dns + "MCM_Damage.jpg",
      hardwareImage: dns + "MCM_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === miuMiu) {
    const brandImages = {
      backImage: dns + "MiuMiu_Back.jpg",
      bottomImage: dns + "MiuMiu_Bottom.jpg",
      labelImage: dns + "MiuMiu_ExteriorLogo.jpg",
      frontImage: dns + "MiuMiu_Front.jpg",
      insideImage: dns + "MiuMiu_Inside.jpg",
      interiorLogoImage: dns + "MiuMiu_InteriorLogo.jpg",
      zipperImage: dns + "MiuMiu_Zipper.jpg",
      damageImage: dns + "MiuMiu_Damage.jpg",
    };
    return brandImages;
  }

  if (value === mulberry) {
    const brandImages = {
      backImage: dns + "Mulberry_Back.jpg",
      bottomImage: dns + "Mulberry_Bottom.jpg",
      frontImage: dns + "Mulberry_Front.jpg",
      insideImage: dns + "Mulberry_Inside.jpg",
      interiorLogoImage: dns + "Mulberry_InteriorLogo.jpg",
      serialImage: dns + "Mulberry_SerialCode.jpg",
      zipperImage: dns + "Mulberry_Zipper.jpg",
      damageImage: dns + "Mulberry_Damage.jpg",
      hardwareImage: dns + "Mulberry_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === offWhite) {
    const brandImages = {
      backImage: dns + "OffWhite_Back.jpg",
      bottomImage: dns + "OffWhite_Bottom.jpg",
      frontImage: dns + "OffWhite_Front.jpg",
      insideImage: dns + "OffWhite_Inside.jpg",
      interiorLogoImage: dns + "OffWhite_InteriorLogo.jpg",
      damageImage: dns + "OffWhite_Damage.jpg",
    };
    return brandImages;
  }

  if (value === prada) {
    const brandImages = {
      backImage: dns + "Prada_Back.jpg",
      bottomImage: dns + "Prada_Bottom.jpg",
      labelImage: dns + "Prada_ExteriorLogo.jpg",
      frontImage: dns + "Prada_Front.jpg",
      insideImage: dns + "Prada_Inside.jpg",
      interiorLogoImage: dns + "Prada_InteriorLogo.jpg",
      serialImage: dns + "Prada_SerialCode.jpg",
      damageImage: dns + "Prada_Damage.jpg",
      hardwareImage: dns + "Prada_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === salvatoreFerragamo) {
    const brandImages = {
      backImage: dns + "SalvatoreFerragamo_Back.jpg",
      bottomImage: dns + "SalvatoreFerragamo_Bottom.jpg",
      labelImage: dns + "SalvatoreFerragamo_ExteriorLogo.jpg",
      frontImage: dns + "SalvatoreFerragamo_Front.jpg",
      insideImage: dns + "SalvatoreFerragamo_Inside.jpg",
      interiorLogoImage: dns + "SalvatoreFerragamo_InteriorLogo.jpg",
      serialImage: dns + "SalvatoreFerragamo_SerialCode.jpg",
      zipperImage: dns + "SalvatoreFerragamo_Zipper.jpg",
      damageImage: dns + "SalvatoreFerragamo_Damage.jpg",
    };
    return brandImages;
  }

  if (value === stellaMcCartney) {
    const brandImages = {
      backImage: dns + "StellaMcCartney_Back.jpg",
      bottomImage: dns + "StellaMcCartney_Bottom.jpg",
      frontImage: dns + "StellaMcCartney_Front.jpg",
      insideImage: dns + "StellaMcCartney_Inside.jpg",
      interiorLogoImage: dns + "StellaMcCartney_InteriorLogo.jpg",
      serialImage: dns + "StellaMcCartney_SerialCode.jpg",
      damageImage: dns + "StellaMcCartney_Damage.jpg",
      hardwareImage: dns + "StellaMcCartney_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === theRow) {
    const brandImages = {
      backImage: dns + "TheRow_Back.jpg",
      bottomImage: dns + "TheRow_Bottom.jpeg",
      labelImage: dns + "TheRow_Label.png",
      frontImage: dns + "TheRow_Front.jpg",
      insideImage: dns + "TheRow_Inside.jpg",
      interiorLogoImage: dns + "TheRow_InteriorLogo.jpg",
      damageImage: dns + "TheRow_Damage.jpg",
      hardwareImage: dns + "TheRow_Hardware.jpg",
    };
    return brandImages;
  }

  if (value === valentino) {
    const brandImages = {
      backImage: dns + "Valentino_Back.jpg",
      bottomImage: dns + "Valentino_Bottom.jpg",
      labelImage: dns + "Valentino_ExteriorLogo.jpg",
      frontImage: dns + "Valentino_Front.jpg",
      insideImage: dns + "Valentino_Inside.jpg",
      interiorLogoImage: dns + "Valentino_InteriorLogo.jpg",
      serialImage: dns + "Valentino_SerialCode.jpg",
      zipperImage: dns + "Valentino_Zipper.jpg",
      damageImage: dns + "Valentino_Damage.jpg",
    };
    return brandImages;
  }

  if (value === versace) {
    const brandImages = {
      backImage: dns + "Versace_Back.jpg",
      bottomImage: dns + "Versace_Bottom.jpg",
      labelImage: dns + "Versace_ExteriorLogo.jpg",
      frontImage: dns + "Versace_Front.jpg",
      insideImage: dns + "Versace_Inside.jpg",
      interiorLogoImage: dns + "Versace_InteriorLogo.jpg",
      zipperImage: dns + "Versace_Zipper.jpg",
      damageImage: dns + "Versace_Damage.jpg",
    };
    return brandImages;
  }

  if (value === saintLaurent) {
    const brandImages = {
      backImage: dns + "YSL_Back.jpg",
      bottomImage: dns + "YSL_Bottom.jpg",
      labelImage: dns + "YSL_ExteriorLogo.jpg",
      frontImage: dns + "YSL_Front.jpg",
      insideImage: dns + "YSL_Inside.jpg",
      interiorLogoImage: dns + "YSL_InteriorLogo.jpg",
      serialImage: dns + "YSL_SerialCode.jpg",
      zipperImage: dns + "YSL_Zipper.jpg",
      damageImage: dns + "YSL_Damage.jpg",
      hardwareImage: dns + "YSL_Hardware.jpg",
    };
    return brandImages;
  }

  const brandImages = {
    backImage: "",
    bottomImage: "",
    labelImage: "",
    frontImage: "",
    insideImage: "",
    interiorLogoImage: "",
    serialImage: "",
    zipperImage: "",
    damageImage: "",
  };
  return brandImages;
};

export const brandValidate = (value) => {
  if (value === alexanderMcqueen) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: true,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === alexanderWang) {
    //   brandedHardware is required

    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === balenciaga) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === balmain) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: true,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },

      labelImage: {
        value: "",
        isValid: true,
      },
    };

    return brandImages;
  }

  if (value === bottegaVeneta) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === burberry) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === bvlgari) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: true,
      },
      labelImage: {
        value: "",
        isValid: true,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === celine) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };

    return brandImages;
  }

  if (value === chanel) {
    // inside image is required
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: true,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === dior) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === dolceGabanna) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },

      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === fendi) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },

      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: true,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === givenchy) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === goyard) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },

      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === gucci) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === hermes) {
    //interior logo is required

    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === jacquemus) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === loewe) {
    const brandImages = {
      frontImage: {
        value: "",
        isValid: false,
      },
      backImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === louisVuitton) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: true,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === mcm) {
    //bottom and serial code are required
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === miuMiu) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === mulberry) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
    };
    return brandImages;
  }

  if (value === offWhite) {
    // inside image and exterior logo are required
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === prada) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      hardwareImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === salvatoreFerragamo) {
    //branded hardware is required
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === stellaMcCartney) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === theRow) {
    //bottom and exterior image are required
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === valentino) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === versace) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  if (value === saintLaurent) {
    const brandImages = {
      backImage: {
        value: "",
        isValid: false,
      },
      bottomImage: {
        value: "",
        isValid: false,
      },
      labelImage: {
        value: "",
        isValid: false,
      },
      frontImage: {
        value: "",
        isValid: false,
      },
      insideImage: {
        value: "",
        isValid: false,
      },
      interiorLogoImage: {
        value: "",
        isValid: false,
      },
      serialImage: {
        value: "",
        isValid: false,
      },
      zipperImage: {
        value: "",
        isValid: true,
      },
      damageImage: {
        value: "",
        isValid: true,
      },
      hardwareImage: {
        value: "",
        isValid: true,
      },
    };
    return brandImages;
  }

  return {};
};
