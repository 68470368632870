import React, { useContext } from 'react';
import Modal from '../../shared/components/UIElements/Modal';
import Container from '../../shared/components/UIElements/ContainerFluid';
import './ModalConfirmation.css';
import Button from '../../shared/components/UIElements/Button';
import { FormContext } from '../../shared/context/form-context';
const ModalConfirmation = props => {
	const form = useContext(FormContext);
	return (
		<Modal
			show={props.show}
			onCancel={props.onCancel}
			className="top-10-vh"
		>
			<Container className="p-t-75 p-b-65 ">
				<div className="text-align-center margin-121">
					<h2 className="does-title padding-b-32">
                        Thanks for submitting your bag
					</h2>
					<p className="details padding-b-24">
                    Your RESELFRIDGES reference number is
						<br />
						<br />
						<b>{form.submissionID}</b>
					</p>

					<p className="details padding-b-16">What happens now?</p>

					<p className="mute">
                        Once we’ve received the images of your bag, a team of valuation experts will carry out
                        a thorough evaluation, including an assessment of its authenticity, resale value,
                        condition and the demand for that particular bag style.
                        If it gets a big yellow tick from the team, we’ll email you with a quote within 4 working days.
                        If your bag is not selected for resale, we’ll email you with an explanation as to why it didn’t make
                        the cut on this occasion.
					</p>
				</div>
			</Container>

			<div className="text-align-center p-b-48">
				<Button className="background-primary lh-40" href="https://www.selfridges.com/">
                    Take me to selfridges.com
				</Button>
			</div>
		</Modal>
	);
};

export default ModalConfirmation;