import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../shared/components/UIElements/Button';
import Container from '../../shared/components/UIElements/Container';
import './Landing.css';

const Landing = () => {
	return (
		<React.Fragment>
			<div alt="banner" className="banner"></div>

			<Container className="padding-small padding-mobile-header">
				<div className="banner-text center">
					<h2 className="reselfridges-title">RESELFRIDGES</h2>
					<p className="banner-subtitle pt-6"><b> Sell your pre-loved accessories with us.</b></p>
					<p className="banner-content-text pt-22"> Welcome to our pioneering new <b>RESELFRIDGES</b> service, which gives you the chance to sell your pre-loved bags in exchange for credits to spend at selfridges.com or in store. Think of it as an exciting new way to refresh your style, clear clutter and close the loop on unnecessary waste.</p>
					<div className="padding-30-28">
						<Button to="/QuoteCreation" className="background-primary"
							action="Button Clicks on the first page(Sell your bag now)" category="first page button">
                            Sell your bag now
						</Button>
					</div>
				</div>

				<p className="mute text-align-center">Please note:  this service is currently only available to customers in the UK and specific European countries. See our <Link to="/FAQs"><u>FAQs</u></Link> for more details.</p>

				<hr className="black"/>
			</Container>

			<Container className="">
				<h2 className="does-title p-t-46 text-align-center subtitle-bottom">HOW DOES IT WORK?</h2>
				<div className="row">
					<div className="col-md-4 widthMax center">

						<div className="oval center">
                            1
						</div>
						<p className="doesDetails text-align-center padding-around"><b>GET YOUR QUOTE</b></p>

						<p className="details maxWidthGroup center text-align-center">Upload images of your bag from the brands listed below and we&apos;ll let you know how much credit you could earn within 4 working days.</p>
					</div>

					<div className="col-md-4 widthMax center padding-responsive-works">
						<div className="oval center">
                            2
						</div>
						<p className="doesDetails text-align-center padding-around"><b>ARRANGE A FREE COLLECTION</b></p>

						<p className="details maxWidthGroup center text-align-center">Schedule a collection date and time that suits you and we&apos;ll take care of the rest.</p>
					</div>

					<div className="col-md-4 widthMax center padding-responsive-works" >
						<div className="oval center">
                            3
						</div>
						<p className="doesDetails text-align-center padding-around"><b>EARN CREDIT</b></p>
						<p className="details maxWidthGroup text-align-center center">Once your bag has been verified, we’ll transfer your credit to a Selfridges eGift Card.</p>
					</div>
				</div>
			</Container>

			<Container className="padding-small">
				<div className="row  background-off-white">
					<div className="col-md-6 padding0">
						<div alt="rectangle" className="Rectangle" />
					</div>
					<div className="col-md-6 pt-37 pb-37 padding0">
						<div className="margin-group">
							<h2 className="whyUseResell ls-0">WHY USE RESELFRIDGES?</h2>

							<h2 className="title why-use-sellfridges-details">Super-fast payment</h2>
							<p className="details pb-15 well-credit-you-as">We’ll credit you within 24 hours of your bag being verified by our expert valuation partner.</p>

							<h2 className="title why-use-sellfridges-details">Invest in a new treasure</h2>
							<p className="details pb-15 well-credit-you-as">Use your credit to invest in a new piece from Selfridges that you’ll cherish for years to come.</p>

							<h2 className="title why-use-sellfridges-details">Fixed quotes</h2>
							<p className="details pb-15 well-credit-you-as">The price we give you is the exact amount of credit you’ll receive.</p>

							<h2 className="title why-use-sellfridges-details">Protect the planet</h2>
							<p className="details pb-15 well-credit-you-as">By selling your bag, you’re doing your bit to extend its life and help close the loop on unnecessary waste. </p>
						</div>
					</div>

				</div>
			</Container>

			<div className="padding-small responsive-accepted-class">

				<Container>
					<h2 className="does-title text-align-center accepted">ACCEPTED BRANDS</h2>

					<div className="row container-margin toRemove">
						<div className="col-sm-3 row-padding">
							<p className="details">Alexander McQueen</p>
							<p className="details">Alexander Wang</p>
							<p className="details">Balenciaga</p>
							<p className="details">Balmain</p>
							<p className="details">Bottega Veneta</p>
							<p className="details">Burberry</p>
							<p className="details">Bulgari</p>
							<p className="details">Celine</p>
						</div>
						<div className="col-sm-3 row-padding">
							<p className="details">Chanel</p>
							<p className="details">Dior</p>
							<p className="details">Dolce & Gabbana</p>
							<p className="details">Fendi</p>
							<p className="details">Givenchy</p>
							<p className="details">Goyard</p>
							<p className="details">Gucci</p>
							<p className="details">Hermès</p>
						</div>
						<div className="col-sm-3 row-padding">
							<p className="details">Jacquemus</p>
							<p className="details">Loewe</p>
							<p className="details">Louis Vuitton</p>
							<p className="details">MCM</p>
							<p className="details">Miu Miu</p>
							<p className="details">Mulberry</p>
							<p className="details">Off-White</p>
							<p className="details">Prada</p>
						</div>
						<div className="col-sm-3 row-padding">
							<p className="details">Saint Laurent</p>
							<p className="details">Salvatore Ferragamo</p>
							<p className="details">Stella McCartney</p>
							<p className="details">The Row</p>
							<p className="details">Valentino</p>
							<p className="details">Versace</p>
						</div>
					</div>

					<div className="row container-margin toShowinMobile">
						<div className="col-6 row-padding">Alexander McQueen</div>
						<div className="col-6 row-padding">Alexander Wang</div>
						<div className="col-6 row-padding">Balenciaga</div>
						<div className="col-6 row-padding">Balmain</div>
						<div className="col-6 row-padding">Burberry</div>
						<div className="col-6 row-padding">Bulgari</div>
						<div className="col-6 row-padding">Celine</div>
						<div className="col-6 row-padding">Chanel</div>
						<div className="col-6 row-padding">Dior</div>
						<div className="col-6 row-padding">Dolce & Gabbana</div>
						<div className="col-6 row-padding">Fendi</div>
						<div className="col-6 row-padding">Givenchy</div>
						<div className="col-6 row-padding">Goyard</div>
						<div className="col-6 row-padding">Gucci</div>
						<div className="col-6 row-padding">Hermès</div>
						<div className="col-6 row-padding">Jacquemus</div>
						<div className="col-6 row-padding">Loewe</div>
						<div className="col-6 row-padding">Louis Vuitton</div>
						<div className="col-6 row-padding">MCM</div>
						<div className="col-6 row-padding">Miu Miu</div>
						<div className="col-6 row-padding">Mulberry</div>
						<div className="col-6 row-padding">Off-White</div>
						<div className="col-6 row-padding">Prada</div>
						<div className="col-6 row-padding">Saint Laurent</div>
						<div className="col-6 row-padding">Salvatore Ferragamo</div>
						<div className="col-6 row-padding">Stella McCartney</div>
						<div className="col-6 row-padding">The Row</div>
						<div className="col-6 row-padding">Valentino</div>
						<div className="col-6 row-padding">Versace</div>
					</div>

					<p className="mute text-align-center">
                        Watch this space as we will be adding more brands to our RESELFRIDGES line-up.
					</p>
					<hr className="black"/>
				</Container>

			</div>

			<Container className="padding-small padding-mobile">
				<h2 className="goTABag text-align-center">GOT A BAG TO SELL?</h2>

				<div className="padding-30-36 text-align-center">
					<Button to="/QuoteCreation" className="background-primary"
						action="Second Button Clicks on the first page(Sell your bag now)" category="first page button">
                            Sell your bag now
					</Button>
				</div>

				<p className="details center text-align-center max-width-969 p-b-60 responsive-margin">
                    Our new <b>RESELFRIDGES</b> services enables you to both resell your pre-loved bags as well as shop a curated collection of pre-loved and archive collections, extending the life of the clothes and accessories we buy or no longer want. It’s all part of our pioneering initiative, Project Earth, which explores new ways to shop and help do our bit for the planet.
				</p>

				<p className="mute center text-align-center max-width-969 responsive-margin">
                For more information about RESELFRIDGES, please read our <Link to="/FAQs"><u>FAQs</u></Link> and <Link to="/TermsConditions"><u>Terms & Conditions</u></Link>. For how we handle your personal information, please see our <a href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/"><u>Privacy & Cookie Policy</u></a>.
				</p>
			</Container>

		</React.Fragment>
	);
};

export default Landing;
