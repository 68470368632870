import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-Selfridges_logo.svg';
import ContainerFluid from '../UIElements/ContainerFluid';
import './MainFooter.css';

const MainFooter = () => {
	return (
		<React.Fragment>
			<div className="p-t-98">
				<div className="background-off-white2">

					<div className="border">

						<div className="background-off-white text-align-center mobile-class-footer-details pt-10">

							<div className="contact pt-20 pb-20"><b> Contact us</b></div>
							<div className="contactDetails pb-30 ">You can call our Customer Services number -
                                we’re here to help with any queries</div>

							<div className="contactDetails p-b-5 mobile-footer-paragraph"><b>UK:</b> 0800 123 400</div>

							<div className="contactDetails p-b-5 mobile-footer-paragraph"><b>Calling from abroad:</b> +44 (0)20 7160 6222</div>

							<div className="contactDetails pb-20 mobile-footer-paragraph"><b>Email:</b> resellfridges@selfridges.com</div>

						</div>
					</div>

					<div className="background-off-white2">
						<ContainerFluid>
							<div className="d-flex flex-wrap p-10 pb-20">
								<a href="https://www.selfridges.com/GB/en/" className="align-self-center pictureRemove" >
									<img src={logo} alt="logo" height="28px" width="177px" />
								</a>
								<div className="ml-auto d-flex flex-wrap ">

									<a href="https://www.selfridges.com/GB/en/features/info/accessibility/">
										<p className="details p-l-r-15 padding-right-mobile">
											<b>Accessibility</b>
										</p>
									</a>

									<span className="dot align-self-center "></span>

									<Link to="/FAQs">
										<p className="details p-l-r-15 ml-100">
											<b>FAQs</b>
										</p>
									</Link>

									<span className="dot align-self-center "></span>

									<a href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/">
										<p className="details p-l-r-15">
											<b>Privacy & Cookies Policies</b>
										</p>
									</a>

									<span className="dot align-self-center "></span>

									<Link to="/TermsConditions">
										<p className="details p-l-r-15">
											<b>Terms & Conditions</b>
										</p>
									</Link>

								</div>

								<div className="padding-tb85">
									<a href="https://www.selfridges.com/GB/en/" className="align-self-center pictureBelow " >
										<img src={logo} alt="logo" height="28px" width="177px" />
									</a>
								</div>
							</div>
						</ContainerFluid>
					</div>
				</div>

			</div>
		</React.Fragment>
	);
};

export default MainFooter;
