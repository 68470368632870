import React from 'react';
import Container from '../shared/components/UIElements/Container';
import './termsandFAQs.css';

const TermsAndConditions = () => {
	return(
		<React.Fragment>
			<Container>
				<div className="center maxWidth876">

					<h1 className="reserlfridges-buyback pt-50">RESELFRIDGES BUYBACK SERVICE TERMS AND CONDITIONS</h1>

					<p className="we-are-excited-that pt-32">
            We are excited that you would like to sell your own Product(s) through the RESELFRIDGES Buyback Service. The RESELFRIDGES Buyback Service Terms and Conditions (the “Terms and Conditions”) set out the terms on which we may agree to purchase the Product(s) from you and the steps each of us will need to take in order to complete this purchase.
						<br/>
						<br/>
						<br/>
The RESELFRIDGES Buyback Service is operated by Selfridges Retail Limited in conjunction with Luxis Baltic (the “Partner”). The Partner is a private limited company under registry code 12824968 in Estonia and is a wholly owned subsidiary of Upteam Co. Ltd who are a company registered in the Philippines. Our Partner is a valuation expert based in Estonia who will be responsible for working with us to organise the collection of your Product and carrying out the Valuation Process and Inspection. Our Partner will then purchase your Products (s) from us (at the Offer Price) to include within its curated pre-loved collections.
						<br/>
						<br/>
Please be sure to read, and make sure you understand, the Terms and Conditions prior to using the RESELFRIDGES Platform and participating in the RESELFRIDGES Buyback Service. By using the RESELFRIDGES Platform and participating in the RESELFRIDGES Buyback Service, you agree to be bound by the Terms and Conditions in respect of your use of the RESELFRIDGES Platform and our purchase of any and all your Product(s).
						<br/>
						<br/>
						<b>1	Definitions</b>
						<br/>

1.1	The following definitions apply to these Terms and Conditions:<br/>
“Collection Information” means details of the address from which the Product will be collected and a collection date and a three-hour window on this date during which you will be available to hand over the Product(s);
						<br/>
						<br/>
“Collection Service” means our Partner’s collection of your Product at the address specified in the Collection Information provided by you;
						<br/>
						<br/>
“EU” means a European Union member state, which, for the time being, includes the UK;
						<br/>
						<br/>
“Inspection” has the definition given to it at Clause 5.1 of these Terms and Conditions;
						<br/>
						<br/>
“Offer Email” means an email sent to you detailing whether or not we have decided to purchase the Product and if so, the Offer Price for the Product;<br/>
						<br/>
“Offer Price” means the value of a Selfridges eGift Card in pound sterling (£) that may be sent to you;<br/>
						<br/>
						<br/>
“Product” means a Product offered for sale by You to us via the RESELFRIDGES Platform;<br/>
						<br/>
“Product Submission” means the combined information as further detailed in Clause 2.3 of these Terms and Conditions:<br/>
						<br/>
“Purchase Confirmation Email” means an email sent by us to you which confirms your Product has passed Inspection and will be purchased at the Offer Price communicated to you <br/>
						<br/>
“RESELFRIDGES Buyback Service” means a buy-back service operated by Selfridges in conjunction with its Partner which facilities the collection and potential purchase of a Product from you in accordance with these Terms and Conditions;<br/>
						<br/>
“RESELFRIDGES Platform” means the website located at www.reselfridges.com, or any subsequent URL which may replace it, and the Selfridges app accessed using a mobile device;<br/>
						<br/>
“Selfridges eGift Card” has the definition given to it at Clause 6.1 of these Terms and Conditions;
“Selfridges Gift Card Terms and Conditions” means the Terms and Conditions which shall govern your use of the Selfridges eGift Card. A current copy of which can be found at <a href="https://www.selfridges.com/GB/en/features/info/gift-card/gift-card-terms/"><u>https://www.selfridges.com/GB/en/features/info/gift-card/gift-card-terms/</u></a> and shall also be supplied to you with the Purchase Confirmation Email;<br/>
						<br/>
“Submission Form” means the electronic form on the RESELFRIDGES Platform which requires you to submit your contact information and information relating to the Product as further detailed in Clause 2 of these Terms and Conditions;<br/>
						<br/>
“Terms and Conditions” means these RESELFRIDGES Buyback Service Terms and Conditions;<br/>
						<br/>
“Valuation Process” has the definition given to it at Clause 2.5 of these Terms and Conditions;<br/>
						<br/>
“We”, “us” or “Selfridges” means Selfridges Retail Limited. Please note, Selfridges has instructed its Partner to: (a) collect and return the Product in accordance with the Collection Information provided by you,(b) carry out the Valuation Process;  and (c) carry out the Inspection of the Product. For the purposes of these Terms and Conditions, all references to “we”, “us”, or “Selfridges”, will be taken to include any such third parties, including but not limited to the Partner.<br/>
						<br/>
“UK” means, for the purposes of these Terms and Conditions, England, Wales, Scotland and Northern Ireland; and<br/>
						<br/>
“You” means a user of the RESELFRIDGES Platform and/or user of the RESELFRIDGES Buyback Service.<br/>
						<br/>
						<b>2	YOUR PRODUCT</b><br/>
2.1	 All Product(s) offered for sale by You as part of the RESELFRIDGES Buyback Service shall be subject at all times to our approval.<br/>
2.2	As part of the approval process, you are required to complete a Submission Form on the RESELFRIDGES Platform. <br/>
2.3	The Submission Form will require that you provide the following information:<br/>
(a)	Your full name;<br/>
(b)	Your contact details including your telephone number and email address; <br/>
(c)	Your country of residence. Please note we are currently only able to accept Product(s) from the EU;<br/>
(d)	Details of the Product, including but not limited to the Product’s brand, style, condition and size; and<br/>
(e)	Images of the Product, including specific images illustrating any damage to the Product and its overall condition,<br/>
(together the “Product Submission”).<br/>
2.4	You warrant to us that you are solely responsible for the Product Submission and that all information you submit to the RESELFRIDGES Platform shall:<br/>
(a)	be true, accurate, current and complete;<br/>
(b)	not infringe the rights of any third party;<br/>
(c)	not contain anything libellous or otherwise unlawful, abusive or obscene nor constitute an invasion of privacy;<br/>
(d)	not contain any content which is obscene or pornographic (including child pornography); and<br/>
(e)	not violate any applicable laws, statutes or regulations.<br/>
2.5	The Product Submission shall be sent directly to our Partner who shall use the information provided by You to determine whether or not an offer for the Product should be made and the value of this offer (the “Valuation Process”). This will also be shared with the RESELFRIDGES Buyback Service team as part of the service offering.  <br/>
						<br/>
						<b>3	OUR OFFER</b><br/>
3.1	Following the Valuation Process, we shall send you an Offer Email.<br/>
3.2	Any Offer Price contained in the Offer Email shall be fixed and we will not accept any counteroffers made by You.<br/>
3.3	Upon receipt of the Offer Email, you shall have the option of accepting or rejecting the Offer Price. <br/>
3.4	If you accept the Offer Price within fourteen days( starting from the date on which you received the Offer Email), this will indicate to us that you are willing to sell your Product to us at the Offer Price.<br/>
3.5	If you do not accept our Offer Price within fourteen days of receipt of the Offer Email, the Offer Price for your Product shall be automatically withdrawn. If you change your mind and wish to accept the Offer Price after we have withdrawn the Offer Price, you will need to resubmit your Product Submission. We shall not be bound by any previous Offer Prices for the same Product and any future offers may differ from the original Offer Price provided in accordance with Clause 3.1 of these Terms and Conditions. <br/>
3.6	Your acceptance of the Offer Price for the Product shall be deemed to have been communicated to us by way of response to the Offer Email. This acceptance of the Offer Price  shall constitute final and irrevocable consent by You to sell the Product to Us and for Us to purchase the Product for the Offer Price. <br/>
3.7	We regret that we may not be able to provide an Offer Price for your Product in certain circumstances and we shall communicate this to you in writing. Circumstances in which we may not be able to make an offer shall include, but not be limited to:<br/>
(a)	being able to determine the authenticity of the Product:<br/>
(b)	the condition of the Product is not suitable;<br/>
(c)	the Product is from a brand we don’t currently accept;<br/>
(d)	the Product is based outside the EU;<br/>
(e)	the Product is not a handbag (we are currently only accepting handbags in the RESELFRIDGES Buyback Service); and/or<br/>
(f)	the Product is not a style we are currently accepting.<br/>
						<br/>
						<b>4	COLLECTION OF YOUR PRODUCT FOR INSPECTION</b><br/>
4.1	Upon acceptance of the Offer Price in accordance with Clause 3.6 of these Terms and Conditions, you will be asked to provide the Collection Information to us via email. We will share the Collection Information with our Partner who will arrange a Collection Service for your Product. We will contact you to inform you that the Collection Service is scheduled and confirmed.<br/>
4.2	You shall not be responsible for any costs associated with the Collection Service, however we shall arrange no more than three (3) separate attempts to collect the Product. After the third unsuccessful attempt, the Offer Price for the Product shall be automatically withdrawn and you will be required to resubmit the Product  in accordance with Clause 2 of these Terms and Conditions. We shall not be bound by any previous Offer Prices for the same Product and any future offers may differ from the original Offer Price provided in accordance with Clause 3.1 of these Terms and Conditions. <br/>
4.3	 You will be required to prepare the Product for the Collection Service in accordance with our written instructions which shall include but not be limited to, providing your own packaging for the Product and printing off a label which should be affixed to your package containing the Product. <br/>
4.4	You shall be solely responsible for ensuring that the Product and all other associated materials included in the Product Submission (including, but not limited to, dust bags, authenticity document and additional accessories accompanying the Product) are included in the package. In the event your Product is received without the additional items referred to in the Product Submission, we shall deem the Product to be unfit for purchase and we shall return the Product to you at our own cost.  <br/>
4.5	We shall assume responsibility for your Product upon collection of the Product from the address provided by you in the Collection Information. In the event that we need to return the Product to you for whatever reason (including but not limited to those reasons listed in Clauses 3.7. 4.4 and 5.1), our responsibility for the Product shall cease upon completion of the delivery of the Product(s) to the address provided by you in the Collection Information. We shall have no responsibility for the Product in the event that the Collection Service is not arranged or used by you.<br/>
4.6	You must not use any other delivery or courier service to deliver the Product to us or our Partner via any process or provider other than the Collection Service listed in this Clause 4. Any Product sent by you and/or received by us or our Partner which has not followed the Collection Service listed in this Clause 4 shall not be accepted and any cost associated with this, as well as the risk, loss or damage to your Product shall be borne by You. <br/>
4.7	Please note that your Product will be inspected by our Partner who is based in Estonia.<br/>
						<br/>
						<b>5	OUR PURCHASE OF THE PRODUCT</b><br/>
5.1	 Upon receipt of your Products, our Partner shall inspect the Product to ensure that it matches the description provided by You in the Product Description, is in a suitable condition and is authentic (the “Inspection”). In the event that the Product is considered in our sole discretion to be in bad condition, severely damaged, counterfeit, is not as described in the Product Description or as otherwise deemed unacceptable, the Product will be deemed unfit for purchase and shall be returned to you at the address listed in the Collection Information at our cost. <br/>
5.2	If your Product passes the Inspection, we shall send to you a Purchase Confirmation Email. <br/>
						<br/>
						<b>6	PAYMENT FOR THE PRODUCT</b><br/>
6.1	 We will send a Selfridges electronic gift card (the “Selfridges eGift Card”) within forty-eight (48 hours) of sending the Purchase Confirmation Email. We shall issue the Selfridges eGift Card to the value of the Offer Price and all amounts shall be in pound sterling
6.2	Please note that the Selfridges eGift Card can be used to purchase goods and services at any of our stores (including selfridges.com). The Selfridges eGift Card is subject to some exclusions (as further detailed in the Selfridges Gift Card Terms and Conditions) and cannot be refunded or exchanged for cash (except in accordance with your legal rights) or used for making Selfridges account payments. Your use of the Selfridges eGift Card shall be at all times in accordance with the Selfridges Gift Card Terms and Conditions.<br/>
						<br/>
						<b>7	WARRANTIES</b><br/>
7.1	In addition to the warranties you provided at Clause 2.4 of these Terms and Conditions, you warrant to us that<br/>
(a)	you are over eighteen (18) years of age at the time of your use of the RESELFRIDGES Buyback Service;<br/>
(b)	you have the all legal rights, title and interest to sell the Product you submit to the RESELFRIDGES Buyback Service and that the Product is free of all liens and encumbrances;<br/>
(c)	the Product is authentic and not counterfeit;<br/>
(d)	the Product is not stolen and/ or has not been acquired by fraudulent means; <br/>
(e)	the Product does not infringe any third-party intellectual property rights;<br/>
(f)	the Product does not contain: fur (including product trim); exotic skins (this includes all skin/leather not derived from cow/calf, sheep/lamb, pig, goat and water buffalo;) and any endangered species listed on CITES Appendix I or the EU Wildlife Trade Regulations Annex A;  and
(g)	the Products is in a clean and hygienic condition, unsoiled and free of any infestations.<br/>
7.2	You agree to fully indemnify, defend and hold harmless Selfridges, it officers, directors, employees, its Partner and suppliers, from and against all claims, liability, damages, losses, costs and expenses, including reasonable legal fees, arising out of any breach of these Terms and Conditions by you or any other liabilities arising out of your use of the RESELFRIDGES Buyback Service.<br/>
						<br/>
						<b>8	YOUR USE OF THE RESELFRIDGES BUYBACK SERVICE</b><br/>
8.1	The RESELFRIDGES Buyback Service is provided solely for your personal use and should not be used for any commercial purposes. As such, we have no liability to you for any loss of profit, business, business opportunity, business interruption or any other indirect losses. <br/>
8.2	 You agree that you are and shall be personally responsible for your use of the RESELFRIDGES Buyback Service (including your use of the RESELFRIDGES Platform). If we determine that you are, or have been, engaged in activities which are unlawful, abusive, obscene, fraudulent, criminal, in contravention of these Terms and Conditions or constitute an invasion of privacy, we may deny your access to the RESELFRIDGES Platform / or the RESELFRIDGES Buyback Service.<br/>
8.3	You must not establish any link to the RESELFRIDGES Buyback Service to suggest any form of association, approval or endorsement on our part where none exists or establish a link from any website that is not owned by you. <br/>
8.4	We make no warranty that the RESELFRIDGES Platform will meet your requirements or will be uninterrupted, timely or error-free, that defects will be corrected, or that the site or the server that makes it available are free of viruses or bugs or represents the full functionality, accuracy, and reliability of the RESELFRIDGES Platform. We will not be responsible or liable to you for any loss of material uploaded or transmitted through the RESELFRIDGES Platform.<br/>
8.5	Whilst we endeavour to verify the accuracy of any information we place on the RESELFRIDGES Platform we make no warranties (whether express or implied) in relation to its accuracy. The RESELFRIDGES Platform is provided on an &quot;as is&quot; and &quot;as available&quot; basis without any representation or endorsement made and we make no warranties of any kind, whether express or implied, in relation to the RESELFRIDGES Platform or any transaction that may be conducted on or through the RESELFRIDGES Platform or the RESELFRIDGES Buyback Service more generally.<br/>
8.6	We may modify or withdraw the RESELFRIDGES Platform (or any part of it) and the RESELFRIDGES Buyback Service temporarily or permanently, and we shall not be liable to you or any third party for any modification to, or withdrawal of, the RESELFRIDGES Platform and the RESELFRIDGES Buyback Service and you agree to use the RESELFRIDGES Platform and the RESELFRIDGES Buyback Service on this basis.<br/>
						<br/>
						<b>9	DATA PROTECTION</b> <br/>
By using the RESELFRIDGES Buyback Service, you understand that we will collect, use, share, store and process your personal information in accordance with our Privacy & Cookie policy. Selfridges fully respects the privacy of individuals who access the RESELFRIDGES Platform and use the RESELFRIDGES Buyback Service. For details on the manner in which we use cookies, the type of information we collect, how and for what purpose, we use your information and under what circumstances we disclose information please see our Privacy & Cookie policy, which are incorporated into and form part of these Terms and Conditions.<br/>
						<br/>
						<b>10	GENERAL</b><br/>
10.1	We may change these Terms and Conditions at any time so please ensure you check the latest version. If you do not agree to any changes in these Terms and Conditions, we advise you to stop using the RESELFRIDGES Platform immediately and/ or withdraw your participation from the RESELFRIDGES Buyback Service.<br/>
10.2	If we make any material changes to the RESELFRIDGES Platform and/or RESELFRIDGES Buyback Service, we will notify you via email or by notice on our website prior to implementation of the change. We shall take any continued use of the RESELFRIDGES Platform and/or RESELFRIDGES Buyback Service following such notice as consent and acceptance of such change. If you do not wish to continue with the RESELFRIDGES Platform and/or RESELFRIDGES Buyback Service following any material change, please contact us as soon as possible.
10.3	If any clause of these Terms and Conditions shall be deemed unlawful, void or for any reason unenforceable, then that clause shall be deemed severable from the Terms and Conditions and shall not affect the validity and enforceability of the remainder of these Terms and Conditions which shall continue to have full force and effect.<br/>
10.4	We shall not be responsible to you for any delay or failure to comply with our obligations under these Terms and Conditions if the delay or failure arises from any cause beyond our, our agents, subcontractors, suppliers or our Partner’s reasonable control.<br/>
10.5	If you breach these Terms and Conditions and we take no action, we will still be entitled to use our rights and remedies in other situations where you are in breach. No waiver by us shall be construed as a waiver of any proceeding or succeeding breach of any clause of these Terms and Conditions. Any waiver of any provision of the Terms and Conditions will only be effective if in writing and signed by a Director of Selfridges.<br/>
10.6	The provisions of the Contracts (Rights of Third Parties) Act 1999 are expressly excluded from these Terms Conditions such that no third party may claim any rights under these Terms and Conditions.<br/>
10.7	All text, graphics, photographs including all image rights, videos, logos, trademarks, artwork, sounds, music, user interfaces, visual interfaces and computer code on the RESELFRIDGES Platform (the &quot;Content&quot;) belongs to Selfridges (or is licensed to Selfridges). This Content includes, but is not limited to, the design, structure, selection, coordination, expression, &quot;look and feel&quot; and arrangement of it. The Content is protected by copyright, trademark and other intellectual property rights and you are not permitted to copy, reproduce, republish, upload, post, publicly display, translate, transmit or distribute this Content in any way without Selfridges&apos; prior written permission.<br/>
10.8	You acknowledge that these Terms and Conditions in electronic format shall have the same force and effect as an agreement in writing.<br/>
10.9	When using the RESELFRIDGES Platform, you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by posting notices on the RESELFRIDGES Platform. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing.<br/>
10.10	The RESELFRIDGES Platform and RESELFRIDGES Buyback Service may only be used for lawful purposes and in a lawful manner. You agree to comply with all applicable laws, statutes and regulations regarding the RESELFRIDGES Buyback Service and any transactions conducted on or through the RESELFRIDGES Platform. These Terms and Conditions are governed by English law and are subject to the exclusive jurisdiction of the English courts.<br/>
						<br/>
						<br/>
						<b>11	OUR DETAILS</b><br/>
Selfridges Retail Limited is registered in England with company number 97117. Its registered office is 400 Oxford Street, London, W1A 1AB.<br/>
					</p>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default TermsAndConditions;
